

@import url('https://fonts.googleapis.com/css?family=Muli:600,700,800');
@import url('https://fonts.googleapis.com/css?family=Lato');
/**
* CSS name convention: BEM
* CSS linting: .stylelintrc
* Global indentation: 4 spaces
* Global units: px
* Browser support: last 3 versions of modern browsers
* Additional notes:
*/
/**
* Import: vars
* Description: variables
*/
/*------------------------------------*\
    # vars.globals
\*------------------------------------*/
/**
 * The $unit variable is a global variable to be used
 * in paddings, margins, for sizing and positioning
 */
/**
 * The $spacer variable is a global variable to be used
 * in to create a unified spacer for all kinds of elements
 */
/**
 * Global class prefix - usage: .$(global-prefix)classname
 */
/**
 * Global radius and rounded
 */
/**
 * Grid
 */
/*------------------------------------*\
    # vars.typography
\*------------------------------------*/
/**
 * Project base line height (unitless in order to work with typomatic)
 */
/**
 * Type scale sizes must be entered in their pixel size
 * (unitless in order to work with typomatic)
 * Default type scale ratio: 1.333;
 */
/** With pixel unit for use in custom styles without typomatic */
/** 
 * Type weights
 */
/**
 * Font stacks
 */
/*------------------------------------*\
    # vars.responsive
\*------------------------------------*/
/* 450px */
/* 449px */
/* 544px */
/* 543px */
/* 768px */
/* 767px */
/* 992px */
/* 991px */
/* 1200px */
/* 1199px */
/* 1440px */
/* 1439px */
/*------------------------------------*\
    # vars.colors
\*------------------------------------*/
/**
 * Global text color
 */
/**
 * Theme colors
 */
/**
 * Utility colors
 */
/**
* Import: tools
* Description: tools like typomatic and other mixins / libraries
*/
/*------------------------------------*\
    # tools.typomatic
\*------------------------------------*/
/**
 * Mixin name: typomatic-init
 * Description: creates a vertical rhythm on a page using font-size
 * and line-height on the html element
 * Parameters: 
 * does not take parameters
 */
/**
 * Mixin name: type-scale
 * Description: type-scale sets the type to baseline to achieve
 * vertical rhythm.
 * Parameters: 
 * $scale ($base-font-size is default) - font size (unitless) variable
 * $baselines (1 is default) - number of baselines
 */
/**
* Import: generic
* Description: normalize or reset CSS, box sizing
*/
/*------------------------------------*\
    # generic.boxsizing
\*------------------------------------*/
*,
*:before,
*:after {
    -webkit-box-sizing: inherit;
            box-sizing: inherit;
}
html {
    -webkit-box-sizing: border-box;
            box-sizing: border-box;
}
/**
* Import: base
* Description: base structural stylings for setting the body, typography
* and other base styles (no classes should .be added here)
*/
/*------------------------------------*\
    # base.globals
\*------------------------------------*/
/**
 * Initialize typomatic in project (sets font-size and line-height
 * on html selector.
 */
html {
    font-size: 100%;
    line-height: 1.25;
}
body {
    position: relative;

    background: #f4f5f5;

    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-weight: 500;
    color: #31263a;

    /* Enable font smoothing for WebKit */
    -webkit-font-smoothing: antialiased;
}
/* Lock scrolling on mobile nav open */
body.is-locked {
        overflow: hidden;
    }
body.is-locked:after {
            content: '';
            position: absolute;
            z-index: 0;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: rgba(0, 0, 0, .3);
        }
@media (max-width: 61.9375em) {
body.is-locked {
            overflow: hidden
    }
        }
img {
    max-width: 100%;
    vertical-align: middle;
}
@media (min-width: 62em) {
.is-mobile {
        display: none
}
    }
@media (min-width: 48em) {
.mobile-only {
        display: none
}
    }
@media (max-width: 47.9375em) {
.desktop-only {
        display: none
}
    }
svg {
    max-width: 100%;
}
/*------------------------------------*\
    # base.typography
\*------------------------------------*/
/**
 * Headings
 */
h1,
h2,
h3,
h4,
h5,
h6 {
    margin: 0;
    font-weight: 500;
}
h1 {
    font-size: 32px;
    line-height: 40px;
}
h2 {
    font-size: 24px;
    line-height: 36px;
}
h3 {
    font-size: 20px;
    line-height: 28px;
}
h4,
h5,
h6 {
    font-size: 16px;
    line-height: 24px;
}
/**
 * Paragraphs
 */
p {
    margin: 0;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    color: #31263a;
}
@media (max-width: 47.9375em) {
p {
        font-size: 14px;
        line-height: 20px
}
    }
@media (min-width: 48em) {
p {
        font-size: 16px;
        line-height: 20px
}
    }
/**
 * Lists (ol, ul, dd)
 */
ol,
ul,
dl {
    margin: 0;
    padding: 0;
    list-style: none;
}
ol {
}
ol li {
}
ul {
}
ul li {
}
dd {
}
dl dt {
}
dl dd {
}
/**
 * Anchors
 */
a {
    color: #004378;
    text-decoration: none;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
a:link {
    }
a:hover {
        color: rgb(0, 0, 0);
    }
a:focus {
        color: rgb(0, 0, 0);
    }
a:visited {
    }
a.anchor--secondary {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    color: #09a593;
}
a.anchor--secondary:hover {
        color: rgb(7, 131, 116);
    }
a.anchor--secondary:focus {
        color: rgb(7, 131, 116);
    }
/**
 * Typographic details
 */
hr {
    padding: 8px 0;
    border: 0;
    border-bottom: 1px solid rgb(222, 222, 222);
}
em {
}
b,
strong {
    font-weight: 700;
}
address {
    font-style: normal;
}
small {
}
pre {
}
code {
}
sub {
}
sup {
}
strike {
}
/**
 * Tables
 */
table th {
        text-align: left;
    }
/**
* Import: component
* Description: cross project reusable components
*/
/*------------------------------------*\
    # component.player
\*------------------------------------*/
.player {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 47.9375em) {
.player {
        padding-bottom: 40px
}
    }
@media (min-width: 48em) {
.player {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -ms-flex-line-pack: center;
            align-content: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        padding: 32px 20px;
        -webkit-column-gap: 20px;
           -moz-column-gap: 20px;
                column-gap: 20px
}
    }
.player__info,
.player__actions,
.player__all-controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.player__info {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
@media (max-width: 47.9375em) {
.player__info {
        position: absolute;
        top: 6px
}
    }
.player__actions {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
}
@media (max-width: 47.9375em) {
.player__actions {
        margin-top: -4px
}
    }
.player__all-controls {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
@media (max-width: 47.9375em) {
.player__all-controls {
        padding: 12px;
        width: 100%
}
    }
.player__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    width: 100%;
}
@media (max-width: 47.9375em) {
.player__controls {
        margin: 8px 0 16px 0
}
    }
@media (min-width: 48em) {
.player__controls {
        max-width: 400px
}
    }
.player__controls .btn {
        height: 28px;
    }
.player__controls .btn img {
        height: 28px;
        width: auto;
    }
@media (max-width: 47.9375em) {
.player__controls .btn img {
            height: 22px
    }
        }
.player__controls .btn:nth-child(3) img {
        height: 32px;
        width: auto;
    }
@media (max-width: 47.9375em) {
.player__controls .btn:nth-child(3) img {
            height: 26px
    }
        }
.player__controls .btn:disabled {
        background: transparent;
        opacity: 0.2;
    }
.player__copy {
    position: relative;
    max-width: 250px;
}
@media (max-width: 47.9375em) {
.player__copy {
        bottom: 3px
}
    }
.player__title {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-size: 12px;
    line-height: 16px;
}
@media (min-width: 48em) {
.player__title {
        white-space: pre-wrap;
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 8px
}
    }
.player__author {
    font-size: 11px;
    line-height: 16px;
}
@media (max-width: 47.9375em) {
.player__author {
        display: none
}
    }
@media (min-width: 48em) {
.player__author {
        font-size: 12px;
        line-height: 16px
}
    }
.player__visual {
    background-size: cover;
    background-position: center;
}
@media (max-width: 47.9375em) {
.player__visual {
        display: none
}
    }
@media (min-width: 48em) {
.player__visual {
        margin-right: 16px;
        width: 68px;
        height: 68px;
        background: #004378;
        border-radius: 2px;
        -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
                box-shadow: 0 2px 6px rgba(0, 0, 0, .1)
}
    }
@media (min-width: 48em) {
.player__naration {
        margin: 12px 0 0 0
}
    }
.player__naration-speed {
    display: inline-block;
    padding: 0 8px;
    font-size: 13px;
    line-height: 20px;
    color: #004378;
    cursor: pointer;
}
@media (min-width: 62em) {
.player__naration-speed {
        padding: 0 12px
}
    }
.player__naration-speed--inactive {
    opacity: 0.4;
}
.player__naration-speed--active {
    opacity: 1;
}
.player__naration-label {
    font-size: 11px;
    line-height: 16px;
    margin-top: 4px;
    text-align: center;
}
/*------------------------------------*\
    # component.audio-item
\*------------------------------------*/
.audio-item {
    position: relative;
    margin-bottom: 12px;
}
@media (max-width: 47.9375em) {
.audio-item {
        width: 100%
}
    }
@media (min-width: 48em) {
.audio-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        width: calc(99.9% * 1/2 - (24px - 24px * 1/2))
}
.audio-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.audio-item:last-child {
        margin-right: 0
}
.audio-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 75em) {
.audio-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        max-width: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        width: calc(99.9% * 1/3 - (32px - 32px * 1/3))
}
.audio-item:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}
.audio-item:last-child {
        margin-right: 0
}
.audio-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.audio-item__button img {
        -webkit-transition: 0.1s all ease-in-out;
        transition: 0.1s all ease-in-out;
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1.01 0 0 0 0 0 1 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="0.45 0.55" /><feFuncG type="table" tableValues="0.45 0.55" /><feFuncB type="table" tableValues="0.45 0.55" /></feComponentTransfer><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.50833 0.62289 0.15309 0 0 0.28269 0.74566 0.13608000000000003 0 0 0.22032000000000002 0.43254000000000004 0.29611 0 0 0 0 0 1 0" /><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="40.31852000000001 -35.721399999999996 -3.59712 0 0 -10.641480000000001 15.748199999999999 -3.59712 0 0 -10.641480000000001 -35.721399999999996 47.362880000000004 0 0 0 0 0 1 0" /><feColorMatrix type="hueRotate" color-interpolation-filters="sRGB" values="343" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.99" /><feFuncG type="linear" slope="0.99" /><feFuncB type="linear" slope="0.99" /></feComponentTransfer><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="0.93" intercept="0.034999999999999976" /><feFuncG type="linear" slope="0.93" intercept="0.034999999999999976" /><feFuncB type="linear" slope="0.93" intercept="0.034999999999999976" /></feComponentTransfer></filter></svg>#filter');
        -webkit-filter: brightness(0) saturate(100%) invert(45%) sepia(81%)
            saturate(5096%) hue-rotate(343deg) brightness(99%) contrast(93%);
                filter: brightness(0) saturate(100%) invert(45%) sepia(81%)
            saturate(5096%) hue-rotate(343deg) brightness(99%) contrast(93%);
    }
.audio-item__button:hover img {
            filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1" /><feFuncG type="linear" slope="1" /><feFuncB type="linear" slope="1" /></feComponentTransfer><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="1 0 0 0 0 0 1.01 0 0 0 0 0 1 0 0 0 0 0 1 0" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="table" tableValues="0.56 0.43999999999999995" /><feFuncG type="table" tableValues="0.56 0.43999999999999995" /><feFuncB type="table" tableValues="0.56 0.43999999999999995" /></feComponentTransfer><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="0.67222 0.41526 0.10206000000000001 0 0 0.18846 0.8304400000000001 0.09072000000000001 0 0 0.14688 0.28836000000000006 0.53074 0 0 0 0 0 1 0" /><feColorMatrix type="matrix" color-interpolation-filters="sRGB" values="2.85732 -1.6873999999999998 -0.16991999999999996 0 0 -0.50268 1.7062 -0.16991999999999996 0 0 -0.50268 -1.6873999999999998 3.19008 0 0 0 0 0 1 0" /><feColorMatrix type="hueRotate" color-interpolation-filters="sRGB" values="315" /><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1.08" /><feFuncG type="linear" slope="1.08" /><feFuncB type="linear" slope="1.08" /></feComponentTransfer><feComponentTransfer color-interpolation-filters="sRGB"><feFuncR type="linear" slope="1.07" intercept="-0.03500000000000003" /><feFuncG type="linear" slope="1.07" intercept="-0.03500000000000003" /><feFuncB type="linear" slope="1.07" intercept="-0.03500000000000003" /></feComponentTransfer></filter></svg>#filter');
            -webkit-filter: brightness(0) saturate(100%) invert(56%) sepia(54%)
                saturate(336%) hue-rotate(315deg) brightness(108%)
                contrast(107%);
                    filter: brightness(0) saturate(100%) invert(56%) sepia(54%)
                saturate(336%) hue-rotate(315deg) brightness(108%)
                contrast(107%);
        }
.audio-item__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    padding: 12px 8px;
}
.audio-item__footer {
    padding: 0 8px;
    margin-bottom: 16px;
}
.audio-item__thumbnail {
    position: relative;
    height: 80px;
    width: 80px;
    border-radius: 8px;
    overflow: hidden;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    border: 1px solid #f4f5f5;
}
.audio-item__thumbnail:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/placeholder-square.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
.audio-item__favorite {
    position: absolute;
    top: 4px;
    left: 4px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: #ffffff;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    padding-top: 4px;
    -webkit-box-shadow: 3px 5px 10px -2px rgba(0, 0, 0, .2);
            box-shadow: 3px 5px 10px -2px rgba(0, 0, 0, .2);
}
.audio-item__progress {
    position: absolute;
    bottom: 0;
    left: 0;
    height: 4px;
    background-color: #004378;
    border-bottom-left-radius: 100px;
    border-bottom-right-radius: 100px;
}
/*------------------------------------*\
    # component.avatar
\*------------------------------------*/
.avatar {
    border-radius: 3px;
    -o-object-fit: cover;
       object-fit: cover;
}
.avatar--lrg {
    height: 110px;
    width: 110px;
}
.avatar--profile {
    margin-right: 12px;
    height: 74px;
    width: 74px;
}
@media (min-width: 48em) {
.avatar--profile {
        margin-right: 24px;
        height: 128px;
        width: 128px
}
    }
.avatar__modal {
    text-align: center;
}
/*------------------------------------*\
    # component.button
\*------------------------------------*/
/** New styleguide */
.button {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    gap: 12px;
    border-radius: 4px;
    border: 1px solid transparent;
    background-color: transparent;
    color: #31263a;

    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 700;

    cursor: pointer;
}
.button:focus {
        outline: 0;
    }
.button:disabled {
        cursor: not-allowed;
        opacity: 0.6;
    }
.button--primary {
    border-color: #09a593;
    background-color: #09a593;
    color: #f4f5f5;
    -webkit-transition: 0.1s background-color ease-in-out;
    transition: 0.1s background-color ease-in-out;
}
.button--primary:hover,
    .button--primary:focus {
        color: #f4f5f5;
        border-color: #006156;
        background-color: #006156;
    }
.button--primary:active {
        color: #f4f5f5;
        border-color: #114f48;
        background-color: #114f48;
    }
.button--primary:disabled {
        opacity: 0.5;
    }
.button--warning {
    border-color: #f44336;
    background-color: #f44336;
    color: #f4f5f5;
    -webkit-transition: 0.1s background-color ease-in-out;
    transition: 0.1s background-color ease-in-out;
}
.button--warning:hover,
    .button--warning:focus {
        color: #f4f5f5;
        border-color: #ff9992;
        background-color: #ff9992;
    }
.button--warning:active {
        color: #f4f5f5;
        border-color: #ffeceb;
        background-color: #ffeceb;
    }
.button--warning:disabled {
        opacity: 0.5;
    }
.button--ghost {
    color: #31263a;
    background-color: transparent;
    border-color: transparent;
}
.button--ghost:hover,
    .button--ghost:focus {
        color: #15130d;
    }
.button--ghost:active {
        color: #15130d;
    }
.button--ghost:disabled {
        opacity: 0.5;
    }
.button--ghost-primary {
    color: #09a593;
    background-color: transparent;
}
.button--ghost-primary:hover,
    .button--ghost-primary:focus {
        color: #006156;
    }
.button--ghost-primary:active {
        color: #114f48;
    }
.button--ghost-warning {
    color: #f44336;
    border-color: transparent;
    -webkit-transition: 0.1s background-color ease-in-out;
    transition: 0.1s background-color ease-in-out;
}
.button--ghost-warning:hover,
    .button--ghost-warning:focus,
    .button--ghost-warning:active {
        color: #ff9992;
    }
.button--outline {
    border-color: #31263a;
    background-color: transparent;
    color: #31263a;
    -webkit-transition: 0.1s all ease-in-out;
    transition: 0.1s all ease-in-out;
}
.button--outline:hover,
    .button--outline:focus {
        color: #f4f5f5;
        background-color: #31263a;
    }
.button--outline:active {
        color: #f4f5f5;
        background-color: #15130d;
    }
.button--outline:disabled {
        opacity: 0.5;
    }
.button--outline-primary {
    border-color: #09a593;
    color: #09a593;
}
.button--outline-primary:hover,
    .button--outline-primary:focus {
        color: #f4f5f5;
        background-color: #09a593;
    }
.button--outline-primary:active {
        color: #f4f5f5;
        background-color: #09a593;
    }
.button--outline-warning {
    border-color: #f44336;
    color: #f44336;
}
.button--outline-warning:hover,
    .button--outline-warning:focus {
        color: #f4f5f5;
        background-color: #f44336;
    }
.button--outline-warning:active {
        color: #f4f5f5;
        background-color: #f44336;
    }
.button--outline-info {
    border-color: #9fd1ff;
    color: #9fd1ff;
}
.button--outline-info:hover,
    .button--outline-info:focus,
    .button--outline-info:active {
        color: #f4f5f5;
        background-color: #2f8bff;
        border-color: #2f8bff;
    }
.button--unpadded {
    padding-left: 0 !important;
    padding-right: 0 !important;
}
.button--tiny {
    padding: 8px 16px;
    height: 24px;
    font-size: 12px;
    line-height: 16px;
}
.button--small {
    height: 32px;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
}
.button--medium {
    height: 44px;
    padding: 12px 20px;
    font-size: 14px;
    line-height: 20px;
}
.button--medium-responsive {
    height: 32px;
    padding: 8px 16px;
    font-size: 12px;
    line-height: 16px;
}
@media (min-width: 48em) {
.button--medium-responsive {
        height: 44px;
        padding: 12px 20px;
        font-size: 14px;
        line-height: 20px
}
    }
.button--blocky {
    border-radius: unset;
}
.button--full-width {
    width: 100%;
}
/*------------------------------------*\
    # component.badge
\*------------------------------------*/
.badge {
    background: #f44336;
    padding: 4px 8px;
    border-radius: 1000px;
    font-size: 11px;
    line-height: 12px;
    color: white;
}
.badge + .badge {
        margin-left: 4px;
    }
.badge--orange {
    background-color: #e2ba45;
}
.badge--red {
    background-color: #f44336;
}
.badge--blue {
    background-color: #004378;
    padding: 4px 16px;
}
.badge--green {
    background-color: #4caf50;
}
.badge--users {
    margin-left: auto;
    margin-right: 8px;
}
/*------------------------------------*\
    # component.card
\*------------------------------------*/
.card {
    position: relative;
    background: white;
    border-radius: 3px;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    border: 1px solid rgba(0, 0, 0, .05);
    border-radius: 8px;
}
.card:hover,
    .card:focus,
    .card:active {
        outline: 0;
        border: 1px solid rgba(0, 0, 0, .1);
        -webkit-box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .2);
                box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .2);
        -webkit-transition: 0.15s all ease-in-out;
        transition: 0.15s all ease-in-out;
    }
.card--dashboard {
    padding: 12px 16px 16px 16px;
}
@media (min-width: 48em) {
.card--dashboard {
        padding: 20px 24px 24px 24px
}
    }
.card--pointer {
    cursor: pointer;
}
.card__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    height: 100%;
}
.cart__content-left {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.card__title {
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 12px;
}
@media (min-width: 48em) {
.card__title {
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 16px
}
    }
.card__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: flex;
    flex-flow: row wrap;
    margin-top: auto;
    -ms-flex-item-align: end;
        align-self: flex-end;
    width: 100%;
}
.card__label {
    font-size: 11px;
    line-height: 16px;
}
@media (min-width: 48em) {
.card__label {
        font-size: 12px;
        line-height: 20px
}
    }
.card__info-half {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/2 - (20px - 20px * 1/2));
    max-width: calc(99.9% * 1/2 - (20px - 20px * 1/2));
    width: calc(99.9% * 1/2 - (20px - 20px * 1/2));
}
.card__info-half:nth-child(1n) {
    margin-right: 20px;
    margin-left: 0;
}
.card__info-half:last-child {
    margin-right: 0;
}
.card__info-half:nth-child(6n) {
    margin-right: 0;
    margin-left: auto;
}
.card__info-third {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 1/3 - (20px - 20px * 1/3));
    max-width: calc(99.9% * 1/3 - (20px - 20px * 1/3));
    width: calc(99.9% * 1/3 - (20px - 20px * 1/3));
}
.card__info-third:nth-child(1n) {
    margin-right: 20px;
    margin-left: 0;
}
.card__info-third:last-child {
    margin-right: 0;
}
.card__info-third:nth-child(4n) {
    margin-right: 0;
    margin-left: auto;
}
.card__info-two-thirds {
    flex-grow: 0;
    flex-shrink: 0;
    flex-basis: calc(99.9% * 2/3 - (20px - 20px * 2/3));
    max-width: calc(99.9% * 2/3 - (20px - 20px * 2/3));
    width: calc(99.9% * 2/3 - (20px - 20px * 2/3));
}
.card__info-two-thirds:nth-child(1n) {
    margin-right: 20px;
    margin-left: 0;
}
.card__info-two-thirds:last-child {
    margin-right: 0;
}
.card__info-two-thirds:nth-child(4n) {
    margin-right: 0;
    margin-left: auto;
}
/*------------------------------------*\
    # component.categories-item
\*------------------------------------*/
.categories-item {
    display: block;
    padding: 20px;
    padding-left: 28px;
    min-height: 120px;
}
@media (max-width: 47.9375em) {
.categories-item {
        width: 100%;
        margin-bottom: 16px
}

        .categories-item + .categories-item {
            margin-top: 8px;
        }
    }
@media (min-width: 48em) {
.categories-item {
        margin-bottom: 24px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        width: calc(99.9% * 1/2 - (24px - 24px * 1/2))
}
.categories-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.categories-item:last-child {
        margin-right: 0
}
.categories-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.categories-item {
        margin-bottom: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/4 - (32px - 32px * 1/4));
        max-width: calc(99.9% * 1/4 - (32px - 32px * 1/4));
        width: calc(99.9% * 1/4 - (32px - 32px * 1/4))
}
.categories-item:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}
.categories-item:last-child {
        margin-right: 0
}
.categories-item:nth-child(4n) {
        margin-right: 0;
        margin-left: auto
}
    }
.categories-item__title {
    max-width: 90%;
}
@media (min-width: 62em) {
.categories-item__title {
        padding-bottom: 16px
}
    }
/*------------------------------------*\
    # component.container
\*------------------------------------*/
.container {
    position: relative;
    overflow: hidden;
    min-height: 100vh;
}
.container--empty {
    height: 100vh;
}
/*------------------------------------*\
    # component.purchase-tickets
\*------------------------------------*/
.purchase-tickets__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    background-color: #f4f5f5;
    border-radius: 8px;
    row-gap: 10px;
    margin-top: 40px;
}
@media (min-width: 48em) {
.purchase-tickets__content {
        margin-top: 68px
}
    }
.purchase-tickets__form {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    height: 100%;
    gap: 40px;
    position: relative;
    border-radius: 12px;
    border: 1px solid #f8f9f9;
    padding: 20px;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(62, 57, 57, 0.35);
            box-shadow: 0px 0px 1px 0px rgba(62, 57, 57, 0.35);
    margin-bottom: 40px;
}
@media (min-width: 48em) {
.purchase-tickets__form {
        padding: 32px;
        margin-bottom: 68px
}

        .purchase-tickets__form:hover,
        .purchase-tickets__form:focus,
        .purchase-tickets__form:active {
            outline: 0;
            border: 1px solid rgba(0, 0, 0, .1);
            -webkit-box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .2);
                    box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .2);
            -webkit-transition: 0.15s all ease-in-out;
            transition: 0.15s all ease-in-out;
        }
    }
.purchase-tickets__form-footer {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 24px;
}
@media (min-width: 48em) {
.purchase-tickets__form-footer {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
.purchase-tickets__cards {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 16px;
}
@media (min-width: 48em) {
.purchase-tickets__cards {
        gap: 32px
}
    }
.purchase-tickets__items__title {
    background-color: #ffffff;
    margin-bottom: 16px;
}
@media (min-width: 48em) {
.purchase-tickets__items__title {
        margin-bottom: 32px
}
    }
.purchase-tickets__item {
    position: relative;
    padding: 24px;
    background: white;
    -webkit-box-shadow: 0px 0px 1px 0px rgba(62, 57, 57, 0.35);
            box-shadow: 0px 0px 1px 0px rgba(62, 57, 57, 0.35);
    border-radius: 12px;
}
.purchase-tickets__item__title__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    margin-bottom: 20px;
}
@media (min-width: 48em) {
.purchase-tickets__item__title__container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        gap: 12px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }
.purchase-tickets__item__title {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 8px;
}
@media (min-width: 48em) {
.purchase-tickets__item__title {
        gap: 12px;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }
.purchase-tickets__items__divider {
    margin-top: 40px;
    margin-bottom: 32px;
}
@media (min-width: 48em) {
.purchase-tickets__items__divider {
        margin-top: 68px;
        margin-bottom: 40px
}
    }
.purchase-tickets__subtotal__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    gap: 32px;
}
.purchase-tickets__remove {
    position: absolute;
    top: 24px;
    right: 24px;
    position: absolute;
    width: 32px;
    height: 32px;

    border: 1px solid #15130d;
    background-color: transparent;
    border-radius: 50%;
    color: #15130d;
    -webkit-transition: 0.1s all ease-in-out;
    transition: 0.1s all ease-in-out;
}
.purchase-tickets__remove:hover,
    .purchase-tickets__remove:focus {
        color: #31263a;
        border: 1px solid #31263a;
        background-color: #efefef;
    }
.purchase-tickets__remove:active {
        color: #31263a;
        border: 1px solid #31263a;
        background-color: #efefef;
    }
.purchase-tickets__remove:disabled {
        opacity: 0.5;
    }
.purchase-tickets__input--radio {
    width: 24px;
    height: 24px;
    accent-color: #004378;
    cursor: pointer;
}
.purchase-tickets__limit__container {
    border-radius: 8px;
    padding: 4px 8px;
    background-color: rgba(141, 205, 255, 0.2);
    color: #2278bc;
}
@media (max-width: 47.9375em) {
.purchase-tickets__limit__container {
        margin-top: -8px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content
}
    }
/*------------------------------------*\
    # component.content
\*------------------------------------*/
.content {
    margin: 16px 0 16px 0;
}
@media (min-width: 48em) {
.content {
        margin: 20px 0 60px 0
}
    }
@media (min-width: 62em) {
.content {
        margin: 28px 0 84px 0
}
    }
.content--dashboard {
    margin: 16px 0 16px 0;
}
@media (min-width: 48em) {
.content--dashboard {
        margin: 24px 0 24px 0
}
    }
@media (min-width: 62em) {
.content--dashboard {
        margin: 48px 0 84px 0
}
    }
.dashboard-toolbar {
    background: white;
}
.dashboard-toolbar__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    padding: 16px 0;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    overflow-x: scroll;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    row-gap: 12px;
}
.dashboard-toolbar__content {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    white-space: nowrap;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin-right: 20px;
}
.dashboard-toolbar__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
    border-radius: 3px;
}
.dashboard-toolbar__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.dashboard-toolbar__item > img {
        width: 24px;
        margin-right: 12px;
        -o-object-fit: cover;
           object-fit: cover;
    }
.dashboard-body {
    background: #f4f5f5;
    padding-top: 40px;
    padding-bottom: 40px;
}
.dashboard-grid {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
/*------------------------------------*\
    # component.dashboard-card
\*------------------------------------*/
@media (min-width: 48em) {
.dashboard-card {
        position: relative;
        background: white;
        border-radius: 3px;
        height: 100%
}
    }
@media (min-width: 48em) {
.dashboard-card__header {
        background: #fcfbfc
}
    }
.dashboard-card__container {
    padding: 0 0 12px 0;
}
@media (min-width: 48em) {
.dashboard-card__container {
        padding: 16px 20px
}
    }
@media (max-width: 33.9375em) {
.dashboard-card__container--media {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        overflow-x: auto;
        -webkit-overflow-scrolling: touch
}
    }
.dashboard-card__container--media::-webkit-scrollbar {
        display: none;
    }
.dashboard-card__container--tickets {
    display: flex;
    flex-flow: row wrap;
}
.dashboard-card__empty {
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    opacity: 0.4;
}
@media (min-width: 48em) {
.dashboard-card__empty {
        font-size: 16px;
        line-height: 24px
}
    }
/*------------------------------------*\
    # component.dashboard-content
\*------------------------------------*/
.dashboard-content {
    display: flex;
    flex-flow: column nowrap;
}
@media (min-width: 48em) {
.dashboard-content {
        display: flex;
        flex-flow: row wrap
}
    }
@media (min-width: 48em) {
.dashboard-content__inner {
        padding-top: 32px;
        padding-bottom: 48px;
        overflow: hidden
}
    }
@media (min-width: 62em) {
.dashboard-content__inner {
        padding-top: 56px;
        padding-bottom: 72px
}
    }
@media (max-width: 47.9375em) {
.dashboard-content__inner--small {
        padding-top: 16px
}
    }
@media (min-width: 48em) {
.dashboard-content__inner--sidebar {
        padding-right: 40px
}
    }
@media (min-width: 62em) {
.dashboard-content__inner--sidebar {
        padding-right: 80px
}
    }
@media (min-width: 48em) {
.dashboard-content__inner--main {
        padding-left: 40px
}
    }
@media (min-width: 62em) {
.dashboard-content__inner--main {
        padding-left: 80px
}
    }
@media (max-width: 47.9375em) {
.dashboard-content__sidebar {
        margin-top: 16px
}
    }
@media (min-width: 48em) {
.dashboard-content__sidebar {
        position: relative;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (0px - 0px * 4/12));
        max-width: calc(99.9% * 4/12 - (0px - 0px * 4/12));
        width: calc(99.9% * 4/12 - (0px - 0px * 4/12));
        background: white
}
.dashboard-content__sidebar:nth-child(1n) {
        margin-right: 0px;
        margin-left: 0
}
.dashboard-content__sidebar:last-child {
        margin-right: 0
}
.dashboard-content__sidebar:nth-child(4n) {
        margin-right: 0;
        margin-left: auto
}

        .dashboard-content__sidebar:before {
            content: '';
            position: absolute;
            z-index: -1;
            top: 0;
            left: -400%;
            width: 400%;
            height: 100%;
            background: white;
        }
    }
@media (min-width: 48em) {
.dashboard-content__sidebar--narrow {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 3/12 - (0px - 0px * 3/12));
        max-width: calc(99.9% * 3/12 - (0px - 0px * 3/12));
        width: calc(99.9% * 3/12 - (0px - 0px * 3/12))
}
.dashboard-content__sidebar--narrow:nth-child(1n) {
        margin-right: 0px;
        margin-left: 0
}
.dashboard-content__sidebar--narrow:last-child {
        margin-right: 0
}
.dashboard-content__sidebar--narrow:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (max-width: 47.9375em) {
.dashboard-content__main {
        margin-top: 28px
}
    }
@media (min-width: 48em) {
.dashboard-content__main {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 8/12 - (0px - 0px * 8/12));
        max-width: calc(99.9% * 8/12 - (0px - 0px * 8/12));
        width: calc(99.9% * 8/12 - (0px - 0px * 8/12))
}
.dashboard-content__main:nth-child(1n) {
        margin-right: 0px;
        margin-left: 0
}
.dashboard-content__main:last-child {
        margin-right: 0
}
.dashboard-content__main:nth-child(8n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 48em) {
.dashboard-content__main--wide {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 9/12 - (0px - 0px * 9/12));
        max-width: calc(99.9% * 9/12 - (0px - 0px * 9/12));
        width: calc(99.9% * 9/12 - (0px - 0px * 9/12))
}
.dashboard-content__main--wide:nth-child(1n) {
        margin-right: 0px;
        margin-left: 0
}
.dashboard-content__main--wide:last-child {
        margin-right: 0
}
.dashboard-content__main--wide:nth-child(9n) {
        margin-right: 0;
        margin-left: auto
}
    }
.dashboard-content__title {
    margin-bottom: 12px;
}
@media (min-width: 48em) {
.dashboard-content__title {
        margin-bottom: 20px
}
    }
@media (max-width: 47.9375em) {
.dashboard-content__sidebar--is-white {
        margin: 0 -20px;
        padding: 4px 20px 8px 20px;
        background: white
}
    }
/*------------------------------------*\
    # component.dashboard-events
\*------------------------------------*/
.dashboard-events__item {
    background: white;
    margin-bottom: 16px;
    padding: 20px;
}
@media (max-width: 33.9375em) {
.dashboard-events__item {
        width: 100%
}
    }
@media (min-width: 34em) {
.dashboard-events__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        max-width: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        width: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        margin-bottom: 12px
}
.dashboard-events__item:nth-child(1n) {
        margin-right: 12px;
        margin-left: 0
}
.dashboard-events__item:last-child {
        margin-right: 0
}
.dashboard-events__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.dashboard-events__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (24px - 24px * 1/3));
        max-width: calc(99.9% * 1/3 - (24px - 24px * 1/3));
        width: calc(99.9% * 1/3 - (24px - 24px * 1/3))
}
.dashboard-events__item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.dashboard-events__item:last-child {
        margin-right: 0
}
.dashboard-events__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.dashboard-events__title {
    font-size: 14px;
    line-height: 24px;
}
@media (min-width: 48em) {
.dashboard-events__title {
        font-size: 16px;
        line-height: 24px
}
    }
/*------------------------------------*\
    # component.dashboard-header
\*------------------------------------*/
.dashboard-header {
    background: linear-gradient(
        135deg,
        rgba(128, 75, 168, 1) 0%,
        rgba(48, 43, 132, 1) 100%
    );

    padding-top: 12px;
}
@media (min-width: 48em) {
.dashboard-header {
        padding-top: 28px
}
    }
.dashboard-header__person {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (min-width: 34em) {
.dashboard-header__person {
        margin-bottom: 8px
}
    }
@media (min-width: 48em) {
.dashboard-header__person {
        margin-bottom: 36px
}
    }
@media (min-width: 62em) {
.dashboard-header__person {
        margin-bottom: 48px
}
    }
.dashboard-header__image {
    height: 32px;
    width: 32px;
    border-radius: 2px;
    -o-object-fit: cover;
       object-fit: cover;
}
@media (min-width: 34em) {
.dashboard-header__image {
        height: 52px;
        width: 52px
}
    }
@media (min-width: 62em) {
.dashboard-header__image {
        height: 80px;
        width: 80px
}
    }
.dashboard-header__nav__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: nowrap;
        flex-wrap: nowrap;
    overflow-x: scroll;
    width: 100%;
    scrollbar-width: none;
}
.dashboard-header__nav__container::-webkit-scrollbar {
        display: none;
    }
.dashboard-header__nav {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 0;
        -ms-flex: 0 0 auto;
            flex: 0 0 auto;

    font-size: 14px;

    line-height: 20px;
}
@media (min-width: 48em) {
.dashboard-header__nav {
        font-size: 16px;
        line-height: 24px
}
    }
@media (min-width: 62em) {
.dashboard-header__nav {
        font-size: 20px;
        line-height: 28px;
        max-width: 1400px
}
    }
.dashboard-header__nav__item {
    display: block;
    padding: 12px 12px;
    color: rgba(255, 255, 255, 0.4);
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
@media (min-width: 62em) {
.dashboard-header__nav__item {
        padding: 12px 20px
}
    }
.dashboard-header__nav__item:first-of-type {
        margin-left: -12px;
    }
@media (min-width: 62em) {
.dashboard-header__nav__item:first-of-type {
            margin-left: -20px
    }
        }
.dashboard-header__nav__item:hover,
    .dashboard-header__nav__item:focus {
        color: rgba(255, 255, 255, 0.6);
    }
.dashboard-header__nav__item.active {
        position: relative;
        color: white;
    }
.dashboard-header__nav__item.active:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #f4f5f5;
        }
@media (min-width: 48em) {
.dashboard-header__nav__item.active:after {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #f4f5f5
        }
            }
.dashboard-header__pick-up {
    opacity: 0.6;
    margin-bottom: 20px;
}
@media (min-width: 34em) {
.dashboard-header__pick-up {
        margin-bottom: 12px
}
    }
/*------------------------------------*\
    # component.dashboard-list-item
\*------------------------------------*/
@media (max-width: 33.9375em) {
.dashboard-list-item {
        margin-right: 16px;
        width: 70%;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto
}

        .dashboard-list-item:last-child {
            margin-right: 0;
        }
    }
@media (min-width: 34em) {
.dashboard-list-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (16px - 16px * 1/3));
        max-width: calc(99.9% * 1/3 - (16px - 16px * 1/3));
        width: calc(99.9% * 1/3 - (16px - 16px * 1/3))
}
.dashboard-list-item:nth-child(1n) {
        margin-right: 16px;
        margin-left: 0
}
.dashboard-list-item:last-child {
        margin-right: 0
}
.dashboard-list-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 48em) {
.dashboard-list-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (20px - 20px * 1/3));
        max-width: calc(99.9% * 1/3 - (20px - 20px * 1/3));
        width: calc(99.9% * 1/3 - (20px - 20px * 1/3))
}
.dashboard-list-item:nth-child(1n) {
        margin-right: 20px;
        margin-left: 0
}
.dashboard-list-item:last-child {
        margin-right: 0
}
.dashboard-list-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.dashboard-list-item__thumbnail {
    padding-top: 56.25%;
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
    background-color: rgb(0, 67, 120);
    border-radius: 3px;
    position: relative;
    overflow: hidden;
}
.dashboard-list-item__content {
    margin-top: 4px;
}
@media (min-width: 48em) {
.dashboard-list-item__content {
        margin-top: 8px
}
    }
/*------------------------------------*\
    # component.dashboard-overview
\*------------------------------------*/
.dashboard-overview__container {
    display: flex;
    flex-flow: column nowrap;
}
@media (min-width: 75em) {
.dashboard-overview__container {
        display: flex;
        flex-flow: row wrap
}
    }
@media (max-width: 74.9375em) {
.dashboard-overview__container > * {
            margin-bottom: 28px
    }
        }
@media (min-width: 75em) {
.dashboard-overview__teams {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        max-width: calc(99.9% * 5/12 - (32px - 32px * 5/12));
        width: calc(99.9% * 5/12 - (32px - 32px * 5/12))
}
.dashboard-overview__teams:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}
.dashboard-overview__teams:last-child {
        margin-right: 0
}
.dashboard-overview__teams:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
.dashboard-overview__teams__card {
    width: 50%;
    padding: 8px 16px;

    display: -webkit-box;

    display: -ms-flexbox;

    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.dashboard-overview__teams__card:nth-child(2) {
        border-left: 1px solid rgba(49, 38, 58, 0.1);
    }
@media (min-width: 75em) {
.dashboard-overview__events {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        width: calc(99.9% * 7/12 - (30px - 30px * 7/12))
}
.dashboard-overview__events:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.dashboard-overview__events:last-child {
        margin-right: 0
}
.dashboard-overview__events:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 34em) {
.dashboard-overview__event-stats {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }
.dashboard-overview__events-item {
    padding: 8px 16px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
@media (min-width: 34em) {
.dashboard-overview__events-item {
        border-right: 1px solid rgba(49, 38, 58, 0.1)
}

        .dashboard-overview__events-item:last-of-type {
            border-right: 0;
        }
    }
@media (min-width: 75em) {
.dashboard-overview__videos,
.dashboard-overview__audios {
        margin-top: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 6/12 - (30px - 30px * 6/12));
        max-width: calc(99.9% * 6/12 - (30px - 30px * 6/12));
        width: calc(99.9% * 6/12 - (30px - 30px * 6/12))
}
.dashboard-overview__videos:nth-child(1n),
.dashboard-overview__audios:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.dashboard-overview__videos:last-child,
.dashboard-overview__audios:last-child {
        margin-right: 0
}
.dashboard-overview__videos:nth-child(2n),
.dashboard-overview__audios:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 75em) {
.dashboard-overview__tickets {
        margin-top: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 12/12 - (30px - 30px * 12/12));
        max-width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
        width: calc(99.9% * 12/12 - (30px - 30px * 12/12))
}
.dashboard-overview__tickets:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.dashboard-overview__tickets:last-child {
        margin-right: 0
}
.dashboard-overview__tickets:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
/*------------------------------------*\
    # component.dashboard-sidebar
\*------------------------------------*/
.dashboard-sidebar__title {
    margin-bottom: 20px;
}
.dashboard-sidebar__item {
    margin-bottom: 28px;
}
@media (min-width: 48em) {
.dashboard-sidebar__item {
        margin-bottom: 40px
}
    }
.dashboard-sidebar__item:last-child {
        margin-bottom: 0;
    }
@media (max-width: 47.9375em) {
.dashboard-sidebar__item--sml {
        margin-bottom: 16px
}
    }
.dashboard-sidebar__stats {
    margin-bottom: 28px;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats {
        margin-bottom: 40px
}
    }
@media (max-width: 47.9375em) {
.dashboard-sidebar__stats {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: flex;
        flex-flow: row wrap;
        border: 1px solid #ecebed;
        border-radius: 3px
}
    }
.dashboard-sidebar__stats__item {
    position: relative;
    padding: 8px 12px;
}
@media (max-width: 47.9375em) {
.dashboard-sidebar__stats__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (0px - 0px * 1/2));
        max-width: calc(99.9% * 1/2 - (0px - 0px * 1/2));
        width: calc(99.9% * 1/2 - (0px - 0px * 1/2));
        border-left: 1px solid #ecebed
}
.dashboard-sidebar__stats__item:nth-child(1n) {
        margin-right: 0px;
        margin-left: 0
}
.dashboard-sidebar__stats__item:last-child {
        margin-right: 0
}
.dashboard-sidebar__stats__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 48em) {
.dashboard-sidebar__stats__item {
        display: block;
        padding: 14px 0
}
    }
.dashboard-sidebar__stats__item:after {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        background-color: #efefef;
    }
@media (min-width: 48em) {
.dashboard-sidebar__stats__item:after {
            width: calc(100% + 40px)
    }
        }
@media (min-width: 62em) {
.dashboard-sidebar__stats__item:after {
            width: calc(100% + 80px)
    }
        }
@media (min-width: 48em) {
.dashboard-sidebar__stats__item:first-child {
            padding-top: 0
    }
        }
.dashboard-sidebar__stats__item:last-child:after {
            display: none;
        }
@media (max-width: 47.9375em) {
.dashboard-sidebar__stats__item--main {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 12/12 - (30px - 30px * 12/12));
        max-width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
        width: calc(99.9% * 12/12 - (30px - 30px * 12/12));
        text-align: center;
        border-bottom: 1px solid #ecebed
}
.dashboard-sidebar__stats__item--main:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.dashboard-sidebar__stats__item--main:last-child {
        margin-right: 0
}
.dashboard-sidebar__stats__item--main:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
.dashboard-sidebar__stats__currency {
    vertical-align: super;
    padding-right: 4px;
    font-size: 12px;
    line-height: 20px;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats__currency {
        font-size: 14px;
        line-height: 20px
}
    }
.dashboard-sidebar__stats__value {
    font-size: 20px;
    line-height: 28px;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats__value {
        font-size: 24px;
        line-height: 32px
}
    }
.dashboard-sidebar__stats__value--price {
    font-size: 24px;
    line-height: 32px;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats__value--price {
        font-size: 32px;
        line-height: 40px
}
    }
@media (max-width: 47.9375em) {
.dashboard-sidebar__stats__value--small {
        font-size: 16px;
        line-height: 20px
}
    }
.dashboard-sidebar__stats__label {
    font-size: 11px;
    line-height: 16px;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats__label {
        font-size: 12px;
        line-height: 20px
}
    }
.dashboard-sidebar__stats__item__row {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 47.9375em) {
.dashboard-sidebar__stats__item__row {
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
.dashboard-sidebar__stats__icon-container {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats__icon-container {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        margin-right: 20px;
        width: 48px;
        max-width: 48px;
        text-align: center
}
    }
@media (max-width: 47.9375em) {
.dashboard-sidebar__stats__icon {
        width: 32px;
        height: auto
}
    }
.dashboard-sidebar__stats__copy-container {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
}
@media (min-width: 48em) {
.dashboard-sidebar__stats__copy-container {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2
}
    }
/*------------------------------------*\
    # component.dashboard-tickets
\*------------------------------------*/
.dashboard-tickets__card-list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    display: flex;
    flex-flow: column nowrap;
}
@media (min-width: 34em) {
.dashboard-tickets__card-list {
        display: flex;
        flex-flow: row wrap
}
    }
.dashboard-tickets__list {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@media (max-width: 47.9375em) {
.dashboard-tickets__list {
        -ms-flex-wrap: nowrap;
            flex-wrap: nowrap;
        overflow-x: scroll;
        width: 100%;
        scrollbar-width: none
}

        .dashboard-tickets__list::-webkit-scrollbar {
            display: none;
        }
    }
@media (min-width: 48em) {
.dashboard-tickets__list {
        display: flex;
        flex-flow: row wrap
}
    }
.dashboard-tickets__item {
    position: relative;
    margin-bottom: 16px;
    background: white;
    overflow: hidden;
}
@media (max-width: 33.9375em) {
.dashboard-tickets__item {
        width: 85%;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto
}
    }
@media (min-width: 34em) and (max-width: 47.9375em) {
.dashboard-tickets__item {
        width: 65%;
        -webkit-box-flex: 0;
            -ms-flex: 0 0 auto;
                flex: 0 0 auto
}
    }
@media (max-width: 47.9375em) {
.dashboard-tickets__item {
        margin-right: 16px
}
    }
@media (min-width: 48em) {
.dashboard-tickets__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        max-width: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        width: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        margin-bottom: 12px
}
.dashboard-tickets__item:nth-child(1n) {
        margin-right: 12px;
        margin-left: 0
}
.dashboard-tickets__item:last-child {
        margin-right: 0
}
.dashboard-tickets__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.dashboard-tickets__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (12px - 12px * 1/3));
        max-width: calc(99.9% * 1/3 - (12px - 12px * 1/3));
        width: calc(99.9% * 1/3 - (12px - 12px * 1/3))
}
.dashboard-tickets__item:nth-child(1n) {
        margin-right: 12px;
        margin-left: 0
}
.dashboard-tickets__item:last-child {
        margin-right: 0
}
.dashboard-tickets__item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.dashboard-tickets__item__qr-container {
    position: relative;
    padding: 40px 20px;
    border-top: 1px solid rgba(0, 0, 0, .1);
    border-right: 1px solid rgba(0, 0, 0, .1);
    border-left: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
    height: 200px;
}
.dashboard-tickets__item__qr-container:before {
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        height: 1px;
        width: 100%;
        border: 1px dashed rgba(0, 0, 0, .1);
    }
.dashboard-tickets__item__holder-container {
    position: relative;
    text-align: center;
    padding: 16px 20px 20px 20px;
    border-right: 1px solid rgba(0, 0, 0, .1);
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    border-left: 1px solid rgba(0, 0, 0, .1);
    border-radius: 3px;
}
.dashboard-tickets__item__holder-container:before {
        content: '';
        position: absolute;
        z-index: 5;
        top: -8px;
        left: -8px;
        width: 16px;
        height: 16px;
        background: #f4f5f5;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 50%;
    }
.dashboard-tickets__item__holder-container:after {
        content: '';
        position: absolute;
        z-index: 5;
        top: -8px;
        right: -8px;
        width: 16px;
        height: 16px;
        background: #f4f5f5;
        border: 1px solid rgba(0, 0, 0, .1);
        border-radius: 50%;
    }
/*------------------------------------*\
    # component.empty-state
\*------------------------------------*/
.empty-state {
    padding: 40px 32px;
    border: 1px dashed rgba(49, 38, 58, 0.1);
    border-radius: 8px;
    text-align: center;
}
@media (min-width: 48em) {
.empty-state {
        padding: 80px 64px
}
    }
.empty-state--error {
    border: none;
}
@media (max-width: 47.9375em) {
.empty-state--error {
        margin-top: 24px
}
    }
.empty-state__visual {
    height: 48px;
    width: auto;
}
@media (min-width: 48em) {
.empty-state__visual {
        height: 72px
}
    }
.empty-state__label {
    margin-top: 16px;
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
    opacity: 0.4;
}
@media (min-width: 48em) {
.empty-state__label {
        margin-top: 24px;
        font-size: 16px;
        line-height: 24px
}
    }
/*------------------------------------*\
    # component.event-item
\*------------------------------------*/
.event-item {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    width: 100%;
    padding: 20px;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    row-gap: 12px;
    cursor: pointer;
}
@media (min-width: 48em) {
.event-item {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row
}
    }
.event-item__thumbnail {
    aspect-ratio: 16/9;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    border-radius: 4px;
    position: relative;
}
.event-item__thumbnail:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/placeholder-16-9.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
@media (min-width: 48em) {
.event-item__thumbnail {
        width: 280px
}
    }
/*------------------------------------*\
    # component.field
\*------------------------------------*/
.field {
    /**
     * Field base
     */
    margin-top: 20px;
}
/* Field label */
.field__lbl {
    display: block;
    padding-bottom: 8px;
    font-size: 12px;
    line-height: 16px;
    opacity: 0.7;
}
/*------------------------------------*\
    # component.formatted
\*------------------------------------*/
.formatted p,
    .formatted ul,
    .formatted ol,
    .formatted dl,
    .formatted table,
    .formatted object,
    .formatted img {
        margin-bottom: 16px;
    }
.formatted h1,
    .formatted h2,
    .formatted h3,
    .formatted h4,
    .formatted h5,
    .formatted h6 {
        margin: 32px 0 8px 0;
        font-weight: 800;
    }
.formatted ul,
    .formatted ol,
    .formatted dl {
        list-style: inherit;
        list-style-position: inside;
    }
.formatted ol {
        list-style-type: decimal;
    }
.formatted ol[type='a'] {
        list-style-type: lower-alpha;
    }
.formatted ol ol {
        margin-left: 16px;
        margin-top: 16px;
    }
.formatted p:last-child {
            margin-bottom: 0 !important;
        }
.formatted blockquote {
        font-style: italic;
        font-size: 20px;
        line-height: 32px;
    }
.formatted ul > li > ul,
    .formatted ul > li > ul > li {
        margin-left: 16px;
        margin-top: 16px;
    }
.formatted small {
        font-size: 70%;
    }
.formatted .numbering {
    }
/*------------------------------------*\
    # component.hamburger
\*------------------------------------*/
.hamburger {
    position: absolute;
    top: 10px;
    right: 12px;
    display: inline-block;
    cursor: pointer;
    z-index: 15;

    -webkit-transition-property: opacity, -webkit-filter;

    transition-property: opacity, -webkit-filter;

    transition-property: opacity, filter;

    transition-property: opacity, filter, -webkit-filter;
    -webkit-transition-duration: 0.15s;
            transition-duration: 0.15s;
    -webkit-transition-timing-function: linear;
            transition-timing-function: linear;

    background-color: transparent;
    border: 0;
    margin: 0;
    padding: 8px;
    overflow: visible;
}
@media (min-width: 62em) {
.hamburger {
        display: none
}
    }
.hamburger-box {
    width: 21px;
    height: 18px;
    display: inline-block;
    position: relative;
}
.hamburger-inner {
    display: block;
    top: 50%;
    width: 21px;
}
.hamburger-inner,
    .hamburger-inner:before,
    .hamburger-inner:after {
        position: absolute;
        height: 2px;

        background-color: #31263a;
        -webkit-transition-property: -webkit-transform;
        transition-property: -webkit-transform;
        transition-property: transform;
        transition-property: transform, -webkit-transform;
        -webkit-transition-duration: 0.15s;
                transition-duration: 0.15s;
        -webkit-transition-timing-function: ease;
                transition-timing-function: ease;
    }
.hamburger-inner:before {
        width: 13px;
    }
.hamburger-inner:after {
        width: 17px;
    }
.hamburger-inner:before,
    .hamburger-inner:after {
        content: '';
        display: block;
    }
.hamburger-inner:before {
        top: -10px;
    }
.hamburger-inner:after {
        bottom: -10px;
    }
.hamburger--alt .hamburger-inner,
    .hamburger--alt .hamburger-inner:before,
    .hamburger--alt .hamburger-inner:after {
        background-color: white;
    }
.hamburger.is-active .hamburger-inner,
    .hamburger.is-active .hamburger-inner:before,
    .hamburger.is-active .hamburger-inner:after {
        background-color: #31263a;
    }
/* Elastic */
.hamburger--elastic .hamburger-inner {
    top: 4px;
    -webkit-transition-duration: 0.275s;
            transition-duration: 0.275s;
    -webkit-transition-timing-function: cubic-bezier(0.68, 0.55, 0.265, 1.55);
            transition-timing-function: cubic-bezier(0.68, 0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner:before {
    top: 4px;
    -webkit-transition: opacity 0.125s 0.275s ease;
    transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner:after {
    top: 8px;
    -webkit-transition: -webkit-transform 0.275s cubic-bezier(0.68, 0.55, 0.265, 1.55);
    transition: -webkit-transform 0.275s cubic-bezier(0.68, 0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, 0.55, 0.265, 1.55);
    transition: transform 0.275s cubic-bezier(0.68, 0.55, 0.265, 1.55), -webkit-transform 0.275s cubic-bezier(0.68, 0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
    top: 0px;
    -webkit-transform: translate3d(0, 8px, 0) rotate(135deg);
            transform: translate3d(0, 8px, 0) rotate(135deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner:before {
    -webkit-transition-delay: 0s;
            transition-delay: 0s;
    opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner:after {
    width: 21px;
    top: 16px;
    -webkit-transform: translate3d(0, -16px, 0) rotate(-270deg);
            transform: translate3d(0, -16px, 0) rotate(-270deg);
    -webkit-transition-delay: 0.075s;
            transition-delay: 0.075s;
}
/*------------------------------------*\
    # component.hero
\*------------------------------------*/
.hero {
}
.hero__container {
    display: flex;
    flex-flow: column nowrap;
    -ms-flex-line-pack: center;
        align-content: center;
    position: relative;
}
@media (min-width: 48em) {
.hero__container {
        display: flex;
        flex-flow: row wrap
}
    }
.hero__title {
    font-size: 40px;
    line-height: 48px;
    text-align: center;
    color: white;
}
@media (max-width: 61.9375em) {
.hero__title {
        margin-bottom: 40px;
        text-shadow: 0 2px 8px rgba(0, 0, 0, .5)
}
    }
@media (min-width: 62em) {
.hero__title {
        padding: 76px 0;
        padding-left: 84px;
        padding-right: 120px;
        font-size: 48px;
        line-height: 56px;
        text-align: left;
        background: linear-gradient(
            135deg,
            rgba(128, 75, 168, 1) 0%,
            rgba(48, 43, 132, 1) 100%
        );
        background-blend-mode: multiply
}
    }
.hero__title-wrap {
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 0;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
@media (max-width: 61.9375em) {
.hero__title-wrap {
        width: 100%;
        text-align: center
}
    }
.hero__image {
    min-height: 548px;
    width: calc(100% + 40px);
    background-image: url('../assets/images/hero-image.jpg');
    background-position: center;
    background-size: cover;
}
@media (max-width: 47.9375em) {
.hero__image {
        margin: 0 -20px
}
    }
@media (min-width: 48em) {
.hero__image {
        min-height: 688px
}
    }
@media (min-width: 48em) and (max-width: 61.9375em) {
.hero__image {
        margin: 0 -40px;
        width: calc(100% + 80px)
}
    }
@media (min-width: 62em) {
.hero__image {
        width: calc(99.9% * 10/12 - (30px - 30px * 10/12));
        position: relative;
        left: calc(99.9% * 2/12 - (30px - 30px * 2/12) + 30px)
}
.hero__image:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none
}
.hero__image:last-child {
        margin-right: 0
}
.hero__image:nth-child(12n) {
        margin-right: 0;
        float: right
}
.hero__image:nth-child(12n + 1) {
        clear: both
}
    }
.hero__cta {
    border-radius: 3px;
}
@media (min-width: 62em) {
.hero__cta {
        width: calc(99.9% * 5/12 - (0px - 0px * 5/12));
        border-radius: 0
}
.hero__cta:nth-child(1n) {
        float: left;
        margin-right: 0px;
        clear: none
}
.hero__cta:last-child {
        margin-right: 0
}
.hero__cta:nth-child(5n) {
        margin-right: 0;
        float: right
}
.hero__cta:nth-child(5n + 1) {
        clear: both
}
    }
/*------------------------------------*\
    # component.history-list
\*------------------------------------*/
.history-list {
    margin-top: 16px;
    border-radius: 3px;
}
@media (min-width: 48em) {
.history-list {
        border: 1px solid rgba(49, 38, 58, 0.1);
        margin-top: 40px
}
    }
.history-list--no-border {
    border: none;
}
.history-list__header,
.history-list__item {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    background: rgba(255, 255, 255, 0.5);
}
@media (min-width: 48em) {
.history-list__header,
.history-list__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: flex;
        flex-flow: row wrap;
        padding: 16px 28px
}
    }
.history-list__header--shared {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    background: none;
    border-bottom: 1px solid rgba(49, 38, 58, 0.08);
}
@media (min-width: 48em) {
.history-list__header--shared {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: flex;
        flex-flow: row wrap;
        padding: 16px 28px
}
    }
.history-list__header {
    background: transparent;
}
@media (max-width: 47.9375em) {
.history-list__header {
        display: none
}
    }
@media (max-width: 47.9375em) {
.history-list__body {
        display: flex;
        flex-flow: row wrap
}
    }
.history-list__title {
    font-size: 14px;
    line-height: 20px;
    color: #004378;
}
@media (min-width: 48em) {
.history-list__title {
        font-size: 16px;
        line-height: 24px
}
    }
.history-list__item {
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}
@media (max-width: 33.9375em) {
.history-list__item {
        width: 100%
}
    }
@media (min-width: 34em) and (max-width: 47.9375em) {
.history-list__item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (16px - 16px * 1/2));
        max-width: calc(99.9% * 1/2 - (16px - 16px * 1/2));
        width: calc(99.9% * 1/2 - (16px - 16px * 1/2))
}
.history-list__item:nth-child(1n) {
        margin-right: 16px;
        margin-left: 0
}
.history-list__item:last-child {
        margin-right: 0
}
.history-list__item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (max-width: 47.9375em) {
.history-list__item {
        padding: 12px 16px;
        border: 1px solid rgba(49, 38, 58, 0.1);
        border-radius: 3px;
        margin-bottom: 16px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap
}
    }
@media (min-width: 48em) {
.history-list__item:nth-child(even) {
            background: transparent
    }
        }
.history-list__item:hover,
    .history-list__item:focus {
        outline: 0;
        -webkit-box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .2);
                box-shadow: 1px 1px 12px -3px rgba(0, 0, 0, .2);
    }
@media (max-width: 47.9375em) {
.history-list__item:hover,
    .history-list__item:focus {
            border: 1px solid rgba(0, 0, 0, .2)
    }
        }
.history-list__item__shared {
    position: relative;
}
@media (max-width: 47.9375em) {
.history-list__item__shared {
        width: 100%
}
    }
@media (max-width: 47.9375em) {
.history-list__item__shared {
        padding: 12px 0;
        margin-bottom: 12px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -ms-flex-wrap: wrap;
            flex-wrap: wrap
}
    }
@media (min-width: 48em) {
.history-list__item__shared {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        display: flex;
        flex-flow: row wrap;
        padding: 16px 28px;
        border-bottom: 1px solid rgba(49, 38, 58, 0.08)
}
    }
.history-list__item__shared:before {
        content: '';
        position: absolute;
        bottom: -4px;
        left: 0;
        width: calc(100% + 20px);
        height: 1px;
        background-color: rgba(49, 38, 58, 0.08);
    }
@media (min-width: 48em) {
.history-list__item__shared:before {
            display: none
    }
        }
.history-list__field {
    font-size: 14px;
    line-height: 20px;
}
@media (max-width: 47.9375em) {
.history-list__field:first-child {
            width: 100%;
            margin-bottom: 8px;
            font-weight: 700
    }
        }
@media (min-width: 48em) {
.history-list__field:first-child {
            -webkit-box-flex: 5;
                -ms-flex: 5;
                    flex: 5
    }
        }
@media (max-width: 47.9375em) {
.history-list__field:nth-child(2) {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1
    }
        }
@media (min-width: 48em) {
.history-list__field:nth-child(2) {
            -webkit-box-flex: 2;
                -ms-flex: 2;
                    flex: 2
    }
        }
@media (max-width: 47.9375em) {
.history-list__field:nth-child(3) {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1
    }
        }
@media (min-width: 48em) {
.history-list__field:nth-child(3) {
            -webkit-box-flex: 2;
                -ms-flex: 2;
                    flex: 2;
            text-align: right
    }
        }
.history-list__field__shared {
    font-size: 14px;
    line-height: 20px;
}
@media (max-width: 47.9375em) {
.history-list__field__shared:first-child {
            margin-bottom: 8px;
            font-weight: 700
    }
        }
@media (min-width: 48em) {
.history-list__field__shared:first-child {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 8/12 - (30px - 30px * 8/12));
            max-width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
            width: calc(99.9% * 8/12 - (30px - 30px * 8/12))
    }
.history-list__field__shared:first-child:nth-child(1n) {
            margin-right: 30px;
            margin-left: 0
    }
.history-list__field__shared:first-child:last-child {
            margin-right: 0
    }
.history-list__field__shared:first-child:nth-child(12n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (max-width: 47.9375em) {
.history-list__field__shared:nth-child(2) {
            -webkit-box-ordinal-group: 4;
                -ms-flex-order: 3;
                    order: 3
    }
        }
@media (min-width: 48em) {
.history-list__field__shared:nth-child(2) {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            width: calc(99.9% * 2/12 - (30px - 30px * 2/12))
    }
.history-list__field__shared:nth-child(2):nth-child(1n) {
            margin-right: 30px;
            margin-left: 0
    }
.history-list__field__shared:nth-child(2):last-child {
            margin-right: 0
    }
.history-list__field__shared:nth-child(2):nth-child(12n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (max-width: 47.9375em) {
.history-list__field__shared:last-child {
            display: block;
            width: 100%;
            -webkit-box-ordinal-group: 3;
                -ms-flex-order: 2;
                    order: 2;
            margin-bottom: 20px
    }
        }
@media (min-width: 48em) {
.history-list__field__shared:last-child {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            text-align: right
    }
.history-list__field__shared:last-child:nth-child(1n) {
            margin-right: 30px;
            margin-left: 0
    }
.history-list__field__shared:last-child:last-child {
            margin-right: 0
    }
.history-list__field__shared:last-child:nth-child(12n) {
            margin-right: 0;
            margin-left: auto
    }
        }
.history-list__field__tickets {
    font-size: 14px;
    line-height: 20px;
}
@media (max-width: 47.9375em) {
.history-list__field__tickets:first-child {
            margin-bottom: 8px;
            font-weight: 700;
            display: block;
            width: 100%
    }
        }
@media (min-width: 48em) {
.history-list__field__tickets:first-child {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 8/12 - (30px - 30px * 8/12));
            max-width: calc(99.9% * 8/12 - (30px - 30px * 8/12));
            width: calc(99.9% * 8/12 - (30px - 30px * 8/12))
    }
.history-list__field__tickets:first-child:nth-child(1n) {
            margin-right: 30px;
            margin-left: 0
    }
.history-list__field__tickets:first-child:last-child {
            margin-right: 0
    }
.history-list__field__tickets:first-child:nth-child(12n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (max-width: 47.9375em) {
.history-list__field__tickets:nth-child(2) {
            display: inline-block;
            margin-right: 8px;
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 6/12 - (20px - 20px * 6/12));
            max-width: calc(99.9% * 6/12 - (20px - 20px * 6/12));
            width: calc(99.9% * 6/12 - (20px - 20px * 6/12))
    }
.history-list__field__tickets:nth-child(2):nth-child(1n) {
            margin-right: 20px;
            margin-left: 0
    }
.history-list__field__tickets:nth-child(2):last-child {
            margin-right: 0
    }
.history-list__field__tickets:nth-child(2):nth-child(6n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (min-width: 48em) {
.history-list__field__tickets:nth-child(2) {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            width: calc(99.9% * 2/12 - (30px - 30px * 2/12))
    }
.history-list__field__tickets:nth-child(2):nth-child(1n) {
            margin-right: 30px;
            margin-left: 0
    }
.history-list__field__tickets:nth-child(2):last-child {
            margin-right: 0
    }
.history-list__field__tickets:nth-child(2):nth-child(12n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (max-width: 47.9375em) {
.history-list__field__tickets:nth-child(3) {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 6/12 - (20px - 20px * 6/12));
            max-width: calc(99.9% * 6/12 - (20px - 20px * 6/12));
            width: calc(99.9% * 6/12 - (20px - 20px * 6/12));
            padding-left: 24px
    }
.history-list__field__tickets:nth-child(3):nth-child(1n) {
            margin-right: 20px;
            margin-left: 0
    }
.history-list__field__tickets:nth-child(3):last-child {
            margin-right: 0
    }
.history-list__field__tickets:nth-child(3):nth-child(6n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (min-width: 48em) {
.history-list__field__tickets:nth-child(3) {
            flex-grow: 0;
            flex-shrink: 0;
            flex-basis: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            max-width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            width: calc(99.9% * 2/12 - (30px - 30px * 2/12));
            text-align: right
    }
.history-list__field__tickets:nth-child(3):nth-child(1n) {
            margin-right: 30px;
            margin-left: 0
    }
.history-list__field__tickets:nth-child(3):last-child {
            margin-right: 0
    }
.history-list__field__tickets:nth-child(3):nth-child(12n) {
            margin-right: 0;
            margin-left: auto
    }
        }
@media (max-width: 47.9375em) {
.history-list__field__mobile-title {
        font-size: 12px;
        line-height: 20px;
        display: block
}
    }
@media (min-width: 48em) {
.history-list__field__mobile-title {
        display: none
}
    }
@media (max-width: 47.9375em) {
.history-list__field__mobile-title--inline {
        font-size: 14px;
        line-height: 20px;
        display: inline-block
}
    }
/*------------------------------------*\
    # component.input
\*------------------------------------*/
.input {
    /**
     * Input base
     */
    padding: 0;
    margin: 0;
    border: 0;

    display: inline-block;
    vertical-align: middle;
    width: 100%;

    /* Prefixed -webkit to remove border radius on iOS */

    /* stylelint-disable property-no-vendor-prefix */
    -webkit-border-radius: 0;
    /* stylelint-enable */

    cursor: pointer;
    white-space: nowrap;

    text-decoration: none;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 500;
    border-radius: 8px;

    background-color: #efefef;
    color: #31263a;
    -webkit-transition: all 0.1s ease-in;
    transition: all 0.1s ease-in;

    /* Default input sizes. Add t-small for small override, etc.  */
    height: 44px;
    padding: 8px 16px;
}
.input:focus {
        border-color: #004378;
        outline: 0;
    }
.input:focus::-webkit-input-placeholder {
            color: #004378;
        }
.input:focus::-moz-placeholder {
            color: #004378;
        }
.input:focus::-ms-input-placeholder {
            color: #004378;
        }
.input:focus::placeholder {
            color: #004378;
        }
/* Input types */
.input--text {
    color: #31263a;
}
.input--error {
    border-color: #f44336;
    font-size: 12px;
    line-height: 16px;
    margin-top: 4px;
    color: #f44336;
}
.input--number {
    padding-right: 0;
}
.input--select {
    position: relative;
    color: #31263a;
    border-radius: 8px;
    padding: 0 16px;
    -webkit-appearance: none;
       -moz-appearance: none;
            appearance: none;
}
.input--select--multiple {
}
.input--textarea {
    min-height: 160px;
    resize: vertical;
    padding-top: 10px;
    white-space: pre-wrap;
    color: #31263a;
    line-height: 20px;
}
@media (min-width: 48em) {
.input--textarea {
        line-height: 20px
}
    }
.input--textarea--sml {
    min-height: 80px;
}
.input--radio + label:before {
            border-radius: 50%;
        }
.input--check-default {
    height: 20px;
    width: 20px;
    padding: 8px;
}
.input--check,
.input--radio {
    position: absolute;
    left: -99999px;
    opacity: 0;
}
.input--check + label, .input--radio + label {
        display: block;
        font-size: 12px;
        line-height: 16px;
        color: rgba(49, 38, 58, 0.5);
    }
.input--check + label:before, .input--radio + label:before {
            content: '';
            display: block;
            float: left;
            width: 16px;
            height: 16px;
            margin-right: 8px;
            -webkit-transition: all 0.2s ease-in-out;
            transition: all 0.2s ease-in-out;
            border: 1px solid #31263a;
            opacity: 0.7;
            border-radius: 1px;
        }
.input--check:focus + label:before, .input--radio:focus + label:before {
                -webkit-box-shadow: 0 0 5px #31263a;
                        box-shadow: 0 0 5px #31263a;
            }
.input--check:checked + label:before, .input--radio:checked + label:before {
                background: #09a593;
                border-color: transparent;
            }
input[type='datetime-local'],
input[type='date'] {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-appearance: none;
}
input[type='datetime-local']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator {
    background-position-x: calc(100% - 12px);
    background-position-y: 50%;
    height: 100%;
    background-size: auto;
    cursor: pointer;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    width: auto;
}
/*------------------------------------*\
    # component.loading
\*------------------------------------*/
.loading {
    display: block;
    position: relative;
    height: 80px;
    width: 80px;
}
.loading--content {
    margin-top: 80px;
    width: 100%;
}
.loading__loader {
    height: 60px;
    width: 64px;
}
.loading__loader--small {
    height: 40px;
    width: 43px;
}
.loading div {
    position: absolute;
    top: 27px;
    width: 11px;
    height: 11px;
    border-radius: 50%;
    background: rgb(0, 123, 224);
    -webkit-animation-timing-function: cubic-bezier(0, 1, 1, 0);
            animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loading div:nth-child(1) {
    left: 6px;
    -webkit-animation: loading1 0.6s infinite;
            animation: loading1 0.6s infinite;
}
.loading div:nth-child(2) {
    left: 6px;
    -webkit-animation: loading2 0.6s infinite;
            animation: loading2 0.6s infinite;
}
.loading div:nth-child(3) {
    left: 26px;
    -webkit-animation: loading2 0.6s infinite;
            animation: loading2 0.6s infinite;
}
.loading div:nth-child(4) {
    left: 45px;
    -webkit-animation: loading3 0.6s infinite;
            animation: loading3 0.6s infinite;
}
.loading--page {
    position: 'absolute';
    height: 100%;
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
@-webkit-keyframes loading1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@keyframes loading1 {
    0% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
    100% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
}
@-webkit-keyframes loading3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@keyframes loading3 {
    0% {
        -webkit-transform: scale(1);
                transform: scale(1);
    }
    100% {
        -webkit-transform: scale(0);
                transform: scale(0);
    }
}
@-webkit-keyframes loading2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}
@keyframes loading2 {
    0% {
        -webkit-transform: translate(0, 0);
                transform: translate(0, 0);
    }
    100% {
        -webkit-transform: translate(19px, 0);
                transform: translate(19px, 0);
    }
}
/*------------------------------------*\
    # component.map-marker
\*------------------------------------*/
.marker {
    position: relative;
    height: 24px;
    width: 24px;
}
.marker svg {
        fill: #09a593;
    }
/*------------------------------------*\
    # component.members-list
\*------------------------------------*/
.member-list-table {
    width: 100%;
    border-collapse: collapse;
}
.member-list-table td {
    padding: 10px 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    /* border-right: none;
    border-top: none; */
}
.member-list-table td:not(:first-child) {
    text-align: right;
}
.dashboard-member-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    padding: 8px 16px;
    margin: 12px 0;
    background: white;
    border-radius: 8px;
}
.member-list__item {
    display: block;
    position: relative;
    margin-bottom: 12px;
    width: 100%;
}
.member-list__item:last-child {
        margin-bottom: 0;
    }
.member-list__avatar {
    position: relative;
    left: -6px;
    margin: 8px 0;
    width: 28px;
    height: 28px;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 3px;
}
@media (min-width: 48em) {
.member-list__avatar {
        left: -8px;
        width: 36px;
        height: 36px
}
    }
.member-list__name {
    margin-left: 4px;
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 12px;
    line-height: 20px;
    color: #004378;
}
@media (min-width: 48em) {
.member-list__name {
        margin-left: 8px;
        font-size: 14px;
        line-height: 20px
}
    }
/*------------------------------------*\
    # component.membership-options
\*------------------------------------*/
.membership-options {
    margin-top: -160px;
    margin-bottom: 5vh;
}
.membership-options__content {
    position: relative;
    padding: 48px 32px;
    border-radius: 2px;
    background: linear-gradient(
        135deg,
        rgba(128, 75, 168, 1) 0%,
        rgba(48, 43, 132, 1) 100%
    );
    border-radius: 2px;
}
@media (min-width: 48em) {
.membership-options__content {
        padding: 88px 60px;
        display: flex;
        flex-flow: row wrap;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }
@media (min-width: 62em) {
.membership-options__content {
        padding: 112px 0
}
    }
.membership-options__content:before {
        position: absolute;
        content: '';
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-image: url('../assets/images/membership-options-background.jpg');
        background-size: cover;
        background-position: top left;
        background-repeat: no-repeat;
        background-blend-mode: multiply;
        opacity: 0.3;
    }
.membership-options__general {
    position: relative;
    z-index: 3;
}
@media (max-width: 47.9375em) {
.membership-options__general {
        margin-bottom: 48px;
        text-align: center
}
    }
@media (min-width: 48em) {
.membership-options__general {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        width: calc(99.9% * 5/12 - (30px - 30px * 5/12))
}
.membership-options__general:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.membership-options__general:last-child {
        margin-right: 0
}
.membership-options__general:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.membership-options__general {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        max-width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        position: relative;
        left: calc(99.9% * 1/12 - (30px - 30px * 1/12) + 30px)
}
.membership-options__general:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.membership-options__general:last-child {
        margin-right: 0
}
.membership-options__general:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.membership-options__general__title {
    font-size: 20px;
    line-height: 32px;
}
@media (min-width: 48em) {
.membership-options__general__title {
        font-size: 24px;
        line-height: 40px
}
    }
@media (min-width: 62em) {
.membership-options__general__title {
        font-size: 32px;
        line-height: 48px
}
    }
.membership-options__general__claim {
    margin-top: 24px;
}
@media (min-width: 48em) {
.membership-options__general__claim {
        margin-top: 48px
}
    }
.membership-options__pricing {
    position: relative;
    background: white;
    border-radius: 2px;
    overflow: hidden;
}
@media (min-width: 48em) {
.membership-options__pricing {
        width: calc(99.9% * 7/12 - (30px - 30px * 7/12))
}
.membership-options__pricing:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none
}
.membership-options__pricing:last-child {
        margin-right: 0
}
.membership-options__pricing:nth-child(12n) {
        margin-right: 0;
        float: right
}
.membership-options__pricing:nth-child(12n + 1) {
        clear: both
}
    }
@media (min-width: 62em) {
.membership-options__pricing {
        width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        position: relative;
        left: calc(99.9% * 2/12 - (30px - 30px * 2/12) + 30px)
}
.membership-options__pricing:nth-child(1n) {
        float: left;
        margin-right: 30px;
        clear: none
}
.membership-options__pricing:last-child {
        margin-right: 0
}
.membership-options__pricing:nth-child(12n) {
        margin-right: 0;
        float: right
}
.membership-options__pricing:nth-child(12n + 1) {
        clear: both
}
    }
.membership-options__pricing__inner {
    padding: 12px 16px 16px 16px;
}
@media (min-width: 48em) {
.membership-options__pricing__inner {
        padding: 20px 24px 24px 24px
}
    }
@media (min-width: 62em) {
.membership-options__pricing__inner {
        padding: 28px 32px 32px 32px
}
    }
.membership-options__pricing__title {
    margin-bottom: 12px;
    font-size: 16px;
    line-height: 24px;
    text-align: center;
}
@media (min-width: 48em) {
.membership-options__pricing__title {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        margin-bottom: 20px;
        font-size: 24px;
        line-height: 40px;
        text-align: left
}
    }
@media (min-width: 62em) {
.membership-options__pricing__title {
        margin-bottom: 28px
}
    }
.membership-options__list-item {
    position: relative;
    padding-bottom: 6px;
    padding-left: 24px;
}
.membership-options__list-item:before {
        position: absolute;
        content: '';
        top: 7px;
        left: 0;
        width: 12px;
        height: 9px;
        background-image: url('../assets/icons/list-check.svg');
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
    }
/*------------------------------------*\
    # component.notification-item
\*------------------------------------*/
.notification-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    padding: 8px 12px;
}
.notification-item + .notification-item {
        border-top: 1px solid #efefef;
    }
.notification-item:hover,
    .notification-item:focus {
        background: rgba(49, 38, 58, 0.1);
    }
.notification-item__text {
    font-size: 12px;
    line-height: 16px;
    padding-right: 8px;
}
.notification-item__time {
    margin-top: 2px;
    color: rgba(49, 38, 58, 0.4);
    font-size: 12px;
    line-height: 16px;
}
.notification-item__action {
    width: 18px;
    height: 18px;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
.notification-item__action:hover circle, .notification-item__action:focus circle {
            fill: #f44336;
        }
/*------------------------------------*\
    # component.notification
\*------------------------------------*/
.notification {
    position: relative;
}
@media (max-width: 61.9375em) {
.notification {
        -webkit-box-ordinal-group: 3;
            -ms-flex-order: 2;
                order: 2
}
    }
@media (max-width: 61.9375em) {
.notification__bell {
        width: 100%;
        height: 60px;
        padding: 0 20px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        border-radius: 0
}
    }
@media (min-width: 62em) {
.notification__bell {
        height: 30px;
        width: 30px;
        border-radius: 1000px
}
    }
.notification__bell:hover {
        background-color: rgba(49, 38, 58, 0.1);
    }
.notification__bell.is-open {
        background-color: rgba(49, 38, 58, 0.2);
    }
.notification__bell__mobile-text {
    text-transform: none;
    text-transform: initial;
    letter-spacing: 0;
    margin-left: 20px;
}
.notification__bell__arrow {
    margin-left: auto;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
@media (min-width: 62em) {
.notification__bell__arrow {
        display: none
}
    }
.notification__bell.is-open .notification__bell__arrow {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
.notification__count {
    border-radius: 10px;
    height: 20px;
    width: 20px;
    background: #09a593;
    color: white;
    font-weight: bold;
    font-size: 12px;
    line-height: 20px;
    text-align: center;
    position: absolute;
    top: -6px;
    right: -6px;
}
@media (max-width: 61.9375em) {
.notification__count {
        top: 10px;
        left: 28px;
        right: unset
}
    }
.notification__list {
    background: #f8f7f9;
    border: 1px solid #ecebed;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    opacity: 0;
    pointer-events: none;
}
@media (max-width: 61.9375em) {
.notification__list {
        width: 100%;
        height: 0
}
    }
@media (min-width: 62em) {
.notification__list {
        width: 360px;
        position: absolute;
        z-index: 2;
        top: 52px;
        left: 0;
        -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
                box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2)
}
    }
.notification__list.is-visible {
        opacity: 1;
        pointer-events: all;
    }
@media (max-width: 61.9375em) {
.notification__list.is-visible {
            height: auto
    }
        }
@media (min-width: 62em) {
.notification__list.is-visible {
            -webkit-transform: translateX(-90%);
                    transform: translateX(-90%)
    }
        }
.notification__clear {
    color: #09a593;
    font-size: 12px;
    height: 16px;
    line-height: 16px;
    padding: 0;
}
.notification__list__header {
    background: white;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 8px 12px;
}
@media (max-width: 61.9375em) {
.notification__list__header {
        display: none
}
    }
.notification__list__title {
    font-size: 13px;
    line-height: 16px;
    font-weight: 700;
}
.notification__list__empty {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    padding: 50px;
}
/*------------------------------------*\
    # component.offline
\*------------------------------------*/
.offline {
    z-index: 5;
    position: fixed;
    bottom: 0;
    left: 0;
    width: 100%;
    background: #f44336;
    -webkit-transform: translateY(100%);
            transform: translateY(100%);
    -webkit-transition: -webkit-transform 0.5s ease-out;
    transition: -webkit-transform 0.5s ease-out;
    transition: transform 0.5s ease-out;
    transition: transform 0.5s ease-out, -webkit-transform 0.5s ease-out;
}
.offline--active {
    -webkit-transform: translateY(0);
            transform: translateY(0);
}
.offline__text {
    color: white;
    padding: 16px 0;
}
/*------------------------------------*\
    # component.page-header
\*------------------------------------*/
.page-header {
    position: relative;
    background-color: #ffffff;
}
.page-header:before {
    content: '';
    display: table;
}
.page-header:after {
    content: '';
    display: table;
    clear: both;
}
@media (max-width: 61.9375em) {
.page-header {
        padding: 12px 0
}
    }
@media (min-width: 62em) {
.page-header {
        padding-top: 80px;
        padding-bottom: 36px
}
    }
@media (min-width: 62em) {
.page-header__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between;
        -webkit-column-gap: 12px;
           -moz-column-gap: 12px;
                column-gap: 12px;
        row-gap: 8px
}
    }
.page-header__container .events__badges {
    margin-left: 20px;
}
.page-header__back {
    z-index: 2;
    position: absolute;
    padding: 4px;
    top: 10px;
    left: 12px;
}
@media (min-width: 62em) {
.page-header__back {
        display: none
}
    }
@media (min-width: 62em) {
.page-header--mobile-only {
        display: none
}
    }
/*------------------------------------*\
    # component.pagination
\*------------------------------------*/
.pagination {
    width: 100%;
}
@media (max-width: 61.9375em) {
.pagination {
        margin-bottom: 110px
}
    }
@media (min-width: 62em) {
.pagination {
        margin-top: 16px
}
    }
.pagination__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.pagination__item {
    margin: 0 8px;
    width: 28px;
    height: 32px;
    font-size: 12px;
    line-height: 20px;
    border: 1px solid rgba(49, 38, 58, 0.1);
    border-radius: 2px;
    color: rgba(0, 67, 120, 0.4);
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
}
@media (max-width: 47.9375em) {
.pagination__item {
        margin-top: 32px
}
    }
@media (min-width: 48em) {
.pagination__item {
        margin: 0 12px;
        width: 32px;
        height: 36px;
        font-size: 14px;
        line-height: 20px
}
    }
.pagination__item:hover,
    .pagination__item:focus {
        color: rgb(0, 67, 120);
    }
.pagination__item--active {
    cursor: not-allowed;
    border: none;
    background: rgba(49, 38, 58, 0.1) !important;
    color: rgb(0, 67, 120) !important;
}
/*------------------------------------*\
    # component.persistent-player
\*------------------------------------*/
.persistent-player {
    position: fixed;
    z-index: 10;
    bottom: 0;
    left: 0;
    width: 100%;
}
.persistent-player__player {
    background: white;
    -webkit-box-shadow: 0 -2px 6px rgba(0, 0, 0, .1);
            box-shadow: 0 -2px 6px rgba(0, 0, 0, .1);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
@media (max-width: 47.9375em) {
.persistent-player__player {
        -webkit-transform: translateY(120px);
                transform: translateY(120px)
}
    }
@media (max-width: 47.9375em) {
.persistent-player__player--expanded {
        -webkit-transform: translateY(0);
                transform: translateY(0)
}
    }
.persistent-player__expand {
    background: white;
    position: absolute;
    top: -24px;
    left: 50%;
    border: 0;
    width: 50px;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    padding: 1px 12px;
    padding-bottom: 4px;
    border-top-left-radius: 1000px;
    border-top-right-radius: 1000px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    -webkit-box-shadow: 0 -2px 6px rgba(0, 0, 0, .1);
            box-shadow: 0 -2px 6px rgba(0, 0, 0, .1);
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
}
@media (min-width: 48em) {
.persistent-player__expand {
        display: none
}
    }
.persistent-player__expand.active svg {
            -webkit-transform: rotate(180deg);
                    transform: rotate(180deg);
        }
/*------------------------------------*\
    # component.playlist
\*------------------------------------*/
.playlist {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    cursor: pointer;
}
.playlist__header {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.playlist__actions {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    -webkit-box-align: 'flex-start';
        -ms-flex-align: 'flex-start';
            align-items: 'flex-start';
    -webkit-box-pack: 'space-between';
        -ms-flex-pack: 'space-between';
            justify-content: 'space-between';
}
.playlist--favorite {
    position: relative;
    padding-bottom: 16px;
}
@media (min-width: 48em) {
        .playlist--favorite:before {
            content: '';
            position: absolute;
            bottom: 0;
            left: 0;
            width: 140%;
            height: 1px;
            background: rgba(49, 38, 58, 0.08);
        }
    }
.playlist__dashboard__header {
    margin-bottom: 16px;
}
@media (max-width: 47.9375em) {
        .playlist__dashboard__header > .btn {
            width: 100%;
        }
    }
@media (min-width: 48em) {
.playlist__dashboard__header {
        margin-bottom: 24px
}
    }
.playlist__list .audio-item {
    width: 100%;
    margin-bottom: 12px;
}
@media (min-width: 62em) {
.playlist__list .audio-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        margin-bottom: 32px
}
.playlist__list .audio-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.playlist__list .audio-item:last-child {
        margin-right: 0
}
.playlist__list .audio-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
.playlist__list .video-list-item {
    width: 100%;
    margin-bottom: 32px;
}
@media (min-width: 62em) {
.playlist__list .video-list-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        margin-bottom: 56px
}
.playlist__list .video-list-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.playlist__list .video-list-item:last-child {
        margin-right: 0
}
.playlist__list .video-list-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
/*------------------------------------*\
    # component.playlist-selector
\*------------------------------------*/
.playlist-selector {
}
.playlist-selector .field {
    margin-top: 8px;
}
/*------------------------------------*\
    # component.profile-menu
\*------------------------------------*/
.profile-menu {
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
}
@media (max-width: 61.9375em) {
.profile-menu {
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1
}
    }
@media (min-width: 62em) {
.profile-menu {
        font-size: 14px;
        line-height: 24px;
        margin-left: 12px;
        border-radius: 3px
}
    }
.profile-menu:hover {
        background: #efefef;
        color: #004378;
    }
.profile-menu--open {
    position: relative;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    background: #f8f7f9;
}
.profile-menu--other {
    height: 0;
    pointer-events: none;
    opacity: 0;
    -webkit-transition: all 0.2s ease-out;
    transition: all 0.2s ease-out;
    overflow: hidden;
}
@media (max-width: 61.9375em) {
.profile-menu--other {
        background: #f8f7f9
}
    }
@media (min-width: 62em) {
.profile-menu--other {
        position: absolute;
        background: white;
        z-index: 2;
        width: 100%;
        min-width: 120px;
        top: 52px;
        -webkit-transform: translateY(-30px);
                transform: translateY(-30px);
        -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
                box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2)
}
    }
.profile-menu--open .profile-menu--other {
    height: auto;
    pointer-events: all;
    opacity: 1;
}
@media (min-width: 62em) {
.profile-menu--open .profile-menu--other {
        border: 1px solid #ecebed;
        -webkit-transform: translateY(0);
                transform: translateY(0)
}
    }
.profile-menu__image {
    height: 20px;
    width: 20px;
    border-radius: 2px;
    -o-object-fit: cover;
       object-fit: cover;
}
.profile-menu__name {
    padding: 8px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;

    font-size: 12px;

    line-height: 16px;
}
@media (max-width: 61.9375em) {
.profile-menu__name {
        width: 100%;
        padding: 20px
}
    }
.profile-menu .profile-menu__link {
    width: 100%;
    text-align: left;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 61.9375em) {
.profile-menu .profile-menu__link {
        padding: 8px 20px
}
    }
@media (min-width: 62em) {
.profile-menu .profile-menu__link {
        padding: 8px 12px
}
    }
.profile-menu .profile-menu__link:hover {
        background: rgba(49, 38, 58, 0.1);
    }
.profile-menu__arrow {
    margin-left: auto;
    -webkit-transition: all 0.2s;
    transition: all 0.2s;
    -webkit-transform: rotate(180deg);
            transform: rotate(180deg);
}
@media (min-width: 62em) {
.profile-menu__arrow {
        margin-left: 8px
}
    }
.profile-menu--open .profile-menu__arrow {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
}
/*------------------------------------*\
    # component.profile
\*------------------------------------*/
.profile {
    padding: 12px 0 20px 0;
}
@media (min-width: 48em) {
.profile {
        padding: 40px 0
}
    }
.profile__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.profile__name {
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-size: 14px;
    line-height: 20px;
}
@media (min-width: 48em) {
.profile__name {
        font-size: 20px;
        line-height: 28px
}
    }
.profile__info {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.profile__actions {
    margin-left: -20px;
}
.profile__team-info {
    position: relative;
}
.profile__team-info a {
        display: block;
    }
.profile__team-info__item {
    position: relative;
    margin-bottom: 16px;
    padding-left: 28px;
}
@media (min-width: 48em) {
.profile__team-info__item {
        margin-bottom: 24px
}
    }
.profile__team-info__item p {
        font-size: 12px;
        line-height: 16px;
    }
.profile__team-info__item:not(:last-child):after {
            content: '';
            position: absolute;
            top: 19px;
            left: 7px;
            width: 1px;
            height: 28px;
            border: 1px dashed rgba(49, 38, 58, 0.2);
        }
@media (min-width: 48em) {
.profile__team-info__item:not(:last-child):after {
                height: 38px
        }
            }
.profile__team-info__item:before {
        content: '';
        position: absolute;
        top: 2px;
        left: 0;
        width: 16px;
        height: 14px;
        background-image: url('../assets/icons/team-diamond-bullet.svg');
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
.profile__subscription-recurring {
    padding-left: 4px;
    font-size: 12px;
    line-height: 20px;
}
/*------------------------------------*\
    # component.seekbar
\*------------------------------------*/
.seekbar {
    -webkit-appearance: none;
    height: 24px;
    width: 100%;
    position: relative;
    top: -12px;
}
@media (min-width: 48em) {
.seekbar {
        height: 6px
}
    }
.seekbar__background {
    background: #d1d6dc;
    position: absolute;
    top: 12px;
    left: 0;
    width: 100%;
    height: 24px;
}
@media (min-width: 48em) {
.seekbar__background {
        height: 6px
}
    }
.seekbar__progress {
    background: #09a593;
    height: 24px;
    position: absolute;
    top: 12px;
    left: 0;
}
@media (min-width: 48em) {
.seekbar__progress {
        height: 6px
}
    }
.seekbar__slider {
    -webkit-appearance: none;
    position: relative;
    z-index: 10;
    width: 100%;
    height: 24px;
    background: none;
    cursor: pointer;
}
@media (max-width: 47.9375em) {
.seekbar__slider {
        top: 12px
}
    }
@media (min-width: 48em) {
.seekbar__slider {
        height: 6px
}
    }
.seekbar__slider::-webkit-slider-thumb {
        -webkit-appearance: none;
        position: relative;
        z-index: 10;
        width: 3px;
        height: 24px;
        background: rgb(10, 115, 103);
        cursor: pointer;
        -webkit-transition: -webkit-transform 0.15s ease-in-out;
        transition: -webkit-transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    }
@media (min-width: 48em) {
.seekbar__slider::-webkit-slider-thumb {
            bottom: 1px;
            border-radius: 50%;
            width: 12px;
            height: 12px;
            background: #09a593
    }
        }
.seekbar__slider::-webkit-slider-thumb:hover,
        .seekbar__slider::-webkit-slider-thumb:focus {
            -webkit-transform: scale(1.3);
                    transform: scale(1.3);
        }
.seekbar__slider::-moz-range-thumb {
        position: relative;
        z-index: 10;
        width: 15px;
        height: 15px;
        border-radius: 50%;
        background: #09a593;
        cursor: pointer;
        -webkit-transition: -webkit-transform 0.15s ease-in-out;
        transition: -webkit-transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out;
        transition: transform 0.15s ease-in-out, -webkit-transform 0.15s ease-in-out;
    }
.seekbar__slider::-moz-range-thumb:hover,
        .seekbar__slider::-moz-range-thumb:focus {
            transform: scale(1.3);
        }
.seekbar__slider:focus {
        outline: none;
    }
.seekbar__duration {
    pointer-events: none;
    padding: 8px 16px 4px 16px;
}
@media (max-width: 47.9375em) {
.seekbar__duration {
        position: absolute;
        top: 8px;
        left: 0;
        width: 100%
}
    }
.seekbar__duration > p {
    font-size: 11px;
    line-height: 16px;
}
.seekbar__time {
}
/*------------------------------------*\
    # component.services-item
\*------------------------------------*/
.services-item {
    display: flex;
    flex-flow: row wrap;
    padding: 24px;
    margin-bottom: 24px;
    width: 100%;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (min-width: 48em) {
.services-item {
        padding: 24px;
        margin-bottom: 32px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        max-width: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        width: calc(99.9% * 1/3 - (32px - 32px * 1/3))
}
.services-item:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}
.services-item:last-child {
        margin-right: 0
}
    }
@media (max-width: 47.9375em) {
.services-item__content {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1
}
    }
@media (min-width: 62em) {
.services-item__content {
        -webkit-box-flex: 1;
            -ms-flex: 1;
                flex: 1
}
    }
.services-item__visual {
    margin-right: 16px;
}
@media (min-width: 48em) and (max-width: 61.9375em) {
.services-item__visual {
        margin-bottom: 24px
}
    }
@media (min-width: 62em) {
.services-item__visual {
        margin-right: 24px
}
    }
/*------------------------------------*\
    # component.services
\*------------------------------------*/
.services {
    background: white;
}
.services__container {
    display: flex;
    flex-flow: row wrap;
    padding-bottom: 224px;
}
@media (min-width: 48em) {
.services__container {
        padding-bottom: 248px
}
    }
.services__title {
    display: block;
    width: 100%;
    margin-top: 72px;
    margin-bottom: 32px;
}
@media (max-width: 47.9375em) {
.services__title {
        text-align: center;
        font-size: 20px;
        line-height: 28px
}
    }
@media (min-width: 48em) {
.services__title {
        font-size: 24px;
        line-height: 36px;
        margin-top: 96px;
        margin-bottom: 48px
}
    }
@media (min-width: 62em) {
.services__title {
        font-size: 32px;
        line-height: 40px;
        margin-top: 112px;
        margin-bottom: 60px
}
    }
/*------------------------------------*\
    # component.stripe
\*------------------------------------*/
.stripe {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: stretch;
        -ms-flex-align: stretch;
            align-items: stretch;
}
@media (max-width: 61.9375em) {
.stripe {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column
}
    }
.stripe__input {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    padding: 16px;
    border-radius: 3px;
    background: white;
    -webkit-box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
            box-shadow: 0 2px 6px rgba(0, 0, 0, .1);
    min-width: 300px;
}
.stripe__input > p {
    margin-top: 4px;
    font-size: 13px;
}
.stripe-card {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    max-width: 50%;
    width: 100%;
}
.stripe-card__info {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
/*------------------------------------*\
    # component.subscription
\*------------------------------------*/
.subscription {
    height: 100%;
}
.subscription__container {
    display: flex;
    flex-flow: column nowrap;
}
@media (min-width: 48em) {
.subscription__container {
        display: flex;
        flex-flow: row wrap;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}
    }
.subscription__plans {
    -webkit-box-ordinal-group: 3;
        -ms-flex-order: 2;
            order: 2;
    text-align: center;
}
@media (min-width: 48em) {
.subscription__plans {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/3 - (30px - 30px * 2/3));
        max-width: calc(99.9% * 2/3 - (30px - 30px * 2/3));
        width: calc(99.9% * 2/3 - (30px - 30px * 2/3));
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1;
        text-align: left
}
.subscription__plans:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.subscription__plans:last-child {
        margin-right: 0
}
.subscription__plans:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.subscription__plans {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 2/3 - (30px - 30px * 2/3));
        max-width: calc(99.9% * 2/3 - (30px - 30px * 2/3));
        width: calc(99.9% * 2/3 - (30px - 30px * 2/3))
}
.subscription__plans:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.subscription__plans:last-child {
        margin-right: 0
}
.subscription__plans:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.subscription__visual {
    -webkit-box-ordinal-group: 2;
        -ms-flex-order: 1;
            order: 1;
    margin-bottom: 24px;
    text-align: center;
}
@media (min-width: 48em) {
.subscription__visual {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        max-width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        width: calc(99.9% * 1/3 - (30px - 30px * 1/3));

        -webkit-box-ordinal-group: 3;

            -ms-flex-order: 2;

                order: 2;
        margin-bottom: 96px
}
.subscription__visual:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.subscription__visual:last-child {
        margin-right: 0
}
.subscription__visual:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.subscription__visual--success {
    margin-top: 80px;
}
@media (min-width: 48em) {
.subscription__visual--success {
        margin-top: 144px
}
    }
.subscription__visual__image {
    width: 140px;
    height: auto;
}
@media (min-width: 34em) {
.subscription__visual__image {
        width: 200px
}
    }
@media (min-width: 48em) {
.subscription__visual__image {
        width: 260px
}
    }
@media (min-width: 62em) {
.subscription__visual__image {
        width: 320px
}
    }
.subscription__buy {
    opacity: 0.3;
    pointer-events: none;
}
.subscription__buy.is-active {
        opacity: 1;
        pointer-events: all;
    }
.subscription__title {
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 12px;
}
@media (min-width: 34em) {
.subscription__title {
        font-size: 20px;
        line-height: 28px
}
    }
@media (min-width: 48em) {
.subscription__title {
        font-size: 24px;
        line-height: 32px;
        margin-bottom: 32px
}
    }
@media (min-width: 62em) {
.subscription__title {
        font-size: 32px;
        line-height: 40px
}
    }
.subscription__plan__item {
    margin-top: 8px;
    margin-bottom: 20px;
    padding: 12px;
    background: #efefef;
    color: #31263a;
    border-radius: 3px;
}
@media (min-width: 48em) {
.subscription__plan__item {
        padding: 20px
}
    }
.subscription__plan__item__label {
    font-size: 12px;
    line-height: 20px;
}
.subscription__plan__item__title {
    font-size: 14px;
    line-height: 20px;
}
@media (min-width: 48em) {
.subscription__plan__item__title {
        font-size: 20px;
        line-height: 28px
}
    }
.subscription__message {
    margin-bottom: 28px;
    font-size: 14px;
    line-height: 20px;
}
@media (min-width: 48em) {
.subscription__message {
        margin-bottom: 36px;
        font-size: 16px;
        line-height: 24px
}
    }
/*------------------------------------*\
    # component.team-item
\*------------------------------------*/
.team-item {
    display: block;
    margin-bottom: 12px;
    width: 100%;
}
@media (min-width: 34em) {
.team-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        max-width: calc(99.9% * 1/2 - (12px - 12px * 1/2));
        width: calc(99.9% * 1/2 - (12px - 12px * 1/2))
}
.team-item:nth-child(1n) {
        margin-right: 12px;
        margin-left: 0
}
.team-item:last-child {
        margin-right: 0
}
.team-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.team-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (24px - 24px * 1/3));
        max-width: calc(99.9% * 1/3 - (24px - 24px * 1/3));
        width: calc(99.9% * 1/3 - (24px - 24px * 1/3))
}
.team-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.team-item:last-child {
        margin-right: 0
}
.team-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
/*------------------------------------*\
    # component.testimonials
\*------------------------------------*/
.testimonials {
    background: rgb(244, 245, 245);
    padding: 96px 0 0 0;
}
@media (min-width: 48em) {
.testimonials {
        padding: 144px 0 0 0
}
    }
.testimonials__title__container {
    margin-bottom: 80px;
    text-align: center;
}
@media (min-width: 48em) {
.testimonials__title__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        display: flex;
        flex-flow: row wrap;
        margin-bottom: 48px;
        text-align: left
}
    }
.testimonials__title {
    font-size: 20px;
    line-height: 28px;
}
@media (min-width: 48em) {
.testimonials__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        margin-left: calc(99.9% * (-5/12 * -1) - (30px - 30px * (-5/12 * -1)) + 30px) !important;
        font-size: 32px;
        line-height: 40px
}
.testimonials__title:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.testimonials__title:last-child {
        margin-right: 0
}
.testimonials__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.testimonials__title {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        margin-left: calc(99.9% * (-6/12 * -1) - (30px - 30px * (-6/12 * -1)) + 30px) !important
}
.testimonials__title:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.testimonials__title:last-child {
        margin-right: 0
}
.testimonials__title:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
.testimonials__content {
    margin-top: -40px;
    background: white;
}
@media (min-width: 48em) {
.testimonials__content {
        padding: 72px 0
}
    }
.testimonials__content__container {
    display: flex;
    flex-flow: column nowrap;
}
@media (min-width: 48em) {
.testimonials__content__container {
        display: flex;
        flex-flow: row wrap
}
    }
.testimonials__content__photo {
    height: 280px;
    width: 100%;
    background-size: cover;
    background-position: top center;
    background-repeat: no-repeat;
    border-radius: 2px;
}
@media (min-width: 34em) {
.testimonials__content__photo {
        height: 360px
}
    }
@media (min-width: 48em) {
.testimonials__content__photo {
        margin-top: -192px;
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 4/12 - (30px - 30px * 4/12));
        max-width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
        width: calc(99.9% * 4/12 - (30px - 30px * 4/12));
        height: 440px
}
.testimonials__content__photo:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.testimonials__content__photo:last-child {
        margin-right: 0
}
.testimonials__content__photo:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.testimonials__content__photo {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        max-width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        width: calc(99.9% * 1/3 - (30px - 30px * 1/3));
        margin-left: calc(99.9% * (-1/12 * -1) - (30px - 30px * (-1/12 * -1)) + 30px) !important;
        height: 440px
}
.testimonials__content__photo:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.testimonials__content__photo:last-child {
        margin-right: 0
}
.testimonials__content__photo:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.testimonials__content__copy {
    margin-top: 28px;
    text-align: center;
}
@media (min-width: 48em) {
.testimonials__content__copy {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        max-width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        width: calc(99.9% * 7/12 - (30px - 30px * 7/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (30px - 30px * (-1/12 * -1)) + 30px) !important;
        text-align: left
}
.testimonials__content__copy:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.testimonials__content__copy:last-child {
        margin-right: 0
}
.testimonials__content__copy:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 62em) {
.testimonials__content__copy {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        max-width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        width: calc(99.9% * 5/12 - (30px - 30px * 5/12));
        margin-left: calc(99.9% * (-1/12 * -1) - (30px - 30px * (-1/12 * -1)) + 30px) !important
}
.testimonials__content__copy:nth-child(1n) {
        margin-right: 30px;
        margin-left: 0
}
.testimonials__content__copy:last-child {
        margin-right: 0
}
.testimonials__content__copy:nth-child(12n) {
        margin-right: 0;
        margin-left: auto
}
    }
.testimonials__content__testimonial {
    position: relative;
    margin-bottom: 24px;
}
@media (min-width: 48em) {
.testimonials__content__testimonial {
        margin-bottom: 32px
}

        .testimonials__content__testimonial:before {
            position: absolute;
            content: '';
            top: -40px;
            left: -48px;
            width: 68px;
            height: 56px;
            background-image: url('../assets/images/testimonials-quote.svg');
            background-position: center;
            background-size: contain;
            background-repeat: no-repeat;
        }
    }
@media (min-width: 62em) {
.testimonials__content__testimonial {
        margin-bottom: 40px
}
    }
.testimonials__slider__controls {
    display: flex;
    flex-flow: row wrap;
    max-width: calc(99.9% * 12/12);
    margin-left: auto;
    margin-right: auto;
}
@media (min-width: 62em) {
.testimonials__slider__controls {
        display: flex;
        flex-flow: row wrap;
        max-width: calc(99.9% * 10/12);
        margin-left: auto;
        margin-right: auto
}
    }
.testimonials__slider-arrows {
    margin-top: 16px;
    padding: 16px 0;
    -webkit-transition: all 0.15s ease;
    transition: all 0.15s ease;
}
@media (max-width: 47.9375em) {
.testimonials__slider-arrows {
        margin-bottom: 28px
}
    }
@media (min-width: 48em) {
.testimonials__slider-arrows {
        margin-top: 40px
}
    }
.testimonials__slider-arrows--left:hover {
        -webkit-transform: translateX(-10px);
                transform: translateX(-10px);
    }
@media (min-width: 48em) {
.testimonials__slider-arrows--left:hover {
            -webkit-transform: translateX(-20px);
                    transform: translateX(-20px)
    }
        }
.testimonials__slider-arrows--right:hover {
        -webkit-transform: translateX(10px);
                transform: translateX(10px);
    }
@media (min-width: 48em) {
.testimonials__slider-arrows--right:hover {
            -webkit-transform: translateX(20px);
                    transform: translateX(20px)
    }
        }
.testimonials__slider-dots {
    position: absolute;
    bottom: -56px;
    left: 50%;
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    text-align: center;
}
@media (min-width: 48em) {
.testimonials__slider-dots {
        bottom: -82px
}
    }
.testimonials__slider-dots > li {
        display: inline-block;
        padding: 12px;
        cursor: pointer;
    }
.testimonials__slider-dots > li > button {
            padding: 0;
            width: 8px;
            height: 8px;
            border-radius: 4px;
            background: none;
            background-color: rgba(49, 38, 58, 0.2);
            border: none;
            -webkit-box-shadow: none;
                    box-shadow: none;
            font-size: 0px;
            text-indent: -1000em;
            white-space: nowrap;
            overflow: hidden;
            cursor: pointer;
        }
.testimonials__slider-dots > li > button:focus,
            .testimonials__slider-dots > li > button:hover {
                outline: none;
            }
.testimonials__slider-dots > .slick-active > button {
        width: 12px;
        height: 12px;
        border-radius: 6px;
        background-color: #09a593;
    }
/*------------------------------------*\
    # component.title
\*------------------------------------*/
.title {
    color: #31263a;
}
.title--main {
    font-weight: 700;
    text-transform: capitalize;
}
@media (max-width: 61.9375em) {
.title--main {
        font-size: 16px;
        line-height: 28px;
        text-align: center
}
    }
@media (min-width: 62em) {
.title--main {
        font-size: 33px;
        line-height: 44px
}
    }
.title--card {
    margin-bottom: 4px;
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-weight: 700;
    color: #004378;
    font-size: 16px;
    line-height: 24px;
}
.title--card-dashboard {
    margin-bottom: 4px;
    font-weight: 700;
    font-size: 12px;
    line-height: 16px;
    color: #004378;
}
@media (min-width: 48em) {
.title--card-dashboard {
        font-size: 14px;
        line-height: 20px
}
    }
/*------------------------------------*\
    # component.toggler
\*------------------------------------*/
.toggler {
    background: #efefef;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    white-space: nowrap;
    padding: 2px;
    border-radius: 3px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.toggler__item {
    color: rgba(49, 38, 58, 0.3);
    font-weight: 400;
}
.toggler__item.active {
        color: #31263a;
        font-weight: 700;
        background: white;
    }
/*------------------------------------*\
    # component.video-list-item
\*------------------------------------*/
.video-list-item {
    position: relative;
    display: block;
    width: 100%;
    margin-bottom: 32px;
    border-radius: 8px;
}
@media (min-width: 34em) {
.video-list-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        width: calc(99.9% * 1/2 - (24px - 24px * 1/2))
}
.video-list-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.video-list-item:last-child {
        margin-right: 0
}
.video-list-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 48em) {
.video-list-item {
        margin-bottom: 56px
}
    }
@media (min-width: 62em) {
.video-list-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        max-width: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        width: calc(99.9% * 1/3 - (32px - 32px * 1/3))
}
.video-list-item:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}
.video-list-item:last-child {
        margin-right: 0
}
.video-list-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.video-list-item:hover .video-list-item__thumbnail {
            -webkit-box-shadow: 1px 1px 24px rgba(49, 38, 58, 0.15);
                    box-shadow: 1px 1px 24px rgba(49, 38, 58, 0.15);
        }
.video-list-item__thumbnail {
    position: relative;
    aspect-ratio: 16/9;
    width: 384px;
    height: auto;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    -webkit-box-shadow: 1px 1px 12px rgba(49, 38, 58, 0.1);
            box-shadow: 1px 1px 12px rgba(49, 38, 58, 0.1);
    border-radius: 8px;
}
.video-list-item__thumbnail:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/placeholder-16-9.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
.video-list-item__favorite {
    position: absolute;
    top: 12px;
    right: 12px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    background: #ffffff;
    height: 32px;
    width: 32px;
    border-radius: 32px;
    padding-top: 4px;
    -webkit-box-shadow: -3px 5px 10px -2px rgba(0, 0, 0, .2);
            box-shadow: -3px 5px 10px -2px rgba(0, 0, 0, .2);
}
/*------------------------------------*\
    # component.wrapper
\*------------------------------------*/
.wrapper {
    max-width: 1400px;
    margin: 0 auto;
    padding: 0 20px;
}
@media (min-width: 48em) {
.wrapper {
        padding: 0 40px
}
    }
@media (min-width: 62em) {
.wrapper {
        padding: 0 96px
}
    }
.wrapper__narrow {
    max-width: 800px;
    margin: 0 auto;
    padding: 0 16px;
}
@media (min-width: 62em) {
.wrapper__narrow {
        padding: unset
}
    }
/*------------------------------------*\
    # component.numbered-item
\*------------------------------------*/
.numbered-item {
}
.numbered-item__no {
    min-width: 28px;
}
/*------------------------------------*\
    # component.report-category
\*------------------------------------*/
.report-category__container {
    position: relative;
    padding: 20px;
    margin-bottom: 12px;
    margin-right: 20px;
    cursor: pointer;
}
.report-category__container:hover {
        filter: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg"><filter id="filter"><feGaussianBlur in="SourceAlpha" stdDeviation="10" /><feOffset dx="3" dy="3" result="offsetblur" /><feFlood flood-color="rgba(0,0,0,0.1)" /><feComposite in2="offsetblur" operator="in" /><feMerge><feMergeNode /><feMergeNode in="SourceGraphic" /></feMerge></filter></svg>#filter');
        -webkit-filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, .1));
                filter: drop-shadow(2px 2px 10px rgba(0, 0, 0, .1));
    }
.report-category__link {
    position: absolute;
    border-radius: inherit;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 1;
    -webkit-transition: -webkit-filter 0.2s ease-out;
    transition: -webkit-filter 0.2s ease-out;
    transition: filter 0.2s ease-out;
    transition: filter 0.2s ease-out, -webkit-filter 0.2s ease-out;
}
.report-category__pointer {
    position: absolute;
    top: 50%;
    right: 20px;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}
.dropdown-menu {
    position: relative;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    overflow: visible;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.dropdown-menu__control {
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border: 0;
    border-radius: 8px;
    background-color: transparent;
    cursor: pointer;
}
.dropdown-menu__control:hover,
    .dropdown-menu__control :focus {
        background-color: rgba(0, 0, 0, .05);
    }
.dropdown-menu__content {
    z-index: 10;
    position: absolute;
    top: calc(100% + 8px);
    right: 0;
    width: 100%;
    min-width: 150px;
    border-radius: 8px;
    background-color: white;
    padding: 4px;
    -webkit-box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
            box-shadow: 0 1px 6px 0 rgba(0, 0, 0, .2);
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    row-gap: 8px;
}
.dropdown-menu__item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    border: 0;
    background-color: transparent;
    padding: 8px 16px;
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: left;
    vertical-align: middle;
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    font-weight: 400;
    color: #31263a;
    font-size: 14px;
    line-height: 24px;
}
.dropdown-menu__item:hover,
    .dropdown-menu__item :focus {
        background-color: rgba(0, 0, 0, .05);
    }
.dropdown-menu__item__icon {
    margin-right: 4px;
    margin-left: -8px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    -webkit-box-flex: 0;
        -ms-flex-positive: 0;
            flex-grow: 0;
    height: 24px;
    width: 24px;
    padding: 6px;
}
.dropdown-menu__separator {
    -webkit-transform: translateX(10%);
            transform: translateX(10%);
    background-color: #f4f5f5;
    width: 80%;
    height: 1px;
}
.toggle-pin {
    position: relative;
    width: 44px;
    height: 24px;
    background-color: #c4c5ca;
    border-radius: 24px;
}
.toggle-pin:before {
        -webkit-transition: 0.1s all ease-out;
        transition: 0.1s all ease-out;
        content: '';
        position: absolute;
        top: 4px;
        left: 4px;
        background-color: white;
        border-radius: 16px;
        height: 16px;
        width: 16px;
    }
.toggle-pin--checked {
    background-color: #004378;
}
.toggle-pin--checked:before {
        -webkit-transition: 0.1s all ease-out;
        transition: 0.1s all ease-out;
        left: calc(100% - 4px);
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
.pill {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 8px 20px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    border-radius: 100px;
    border: 1px solid #aab0b7;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-size: 11px;
    line-height: 16px;
    font-style: normal;
    font-weight: 400;
    letter-spacing: 2px;
    text-transform: uppercase;
}
@media (max-width: 33.9375em) {
.pill {
        font-size: 8px;
        line-height: 11px;
        padding: 4px 12px;
        max-height: 20px
}
    }
.pill__full--note {
    border-color: #e2ba45;
    background-color: #e2ba45;
    color: white;
    font-weight: 600;
}
.pill--sml {
    font-size: 8px;
    line-height: 11px;
    padding: 4px 12px;
    max-height: 20px;
}
.pill--info {
    color: #2f8bff;
    border-color: #2f8bff;
}
.pill--warning {
    color: #f44336;
    border-color: #f44336;
}
.event-form__remove-ticket-type {
    height: 42px;
    width: 42px;
    -webkit-transition: 0.2s border ease-in-out;
    transition: 0.2s border ease-in-out;
    margin-top: 24px;
    border-radius: 8px;
}
> .event-form__remove-ticket-typebutton:hover {
        outline: 1px solid #c4c5ca;
    }
.event-form__add-ticket-type {
    padding-left: 0;
}
.event-form__section {
    border-top: 1px solid #c4c5ca;
    padding-top: 32px;
    margin-top: 24px;
}
.media-filters {
    margin-bottom: 24px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    row-gap: 12px;
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.media-filters__control {
    min-width: 100px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    row-gap: 12px;
    padding: 4px 0;
}
.media-filters__caret {
    -webkit-transform: rotate(90deg);
            transform: rotate(90deg);
    -ms-flex-negative: 0;
        flex-shrink: 0;
    height: 12px;
    width: 12px;
    min-height: 12px;
    min-width: 12px;
}
.notice {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    border-radius: 4px;
    padding: 8px 16px;
    gap: 12px;
}
.notice--info {
    background-color: #edf6ff;
}
.notice--info * {
        color: #2f8bff;
    }
.event-ticket-item {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    padding: 16px;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
}
@media (max-width: 47.9375em) {
.event-ticket-item {
        width: 100%;
        margin-bottom: 20px
}
    }
@media (min-width: 48em) {
.event-ticket-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        max-width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        width: calc(99.9% * 1/2 - (24px - 24px * 1/2));
        margin-bottom: 0
}
.event-ticket-item:nth-child(1n) {
        margin-right: 24px;
        margin-left: 0
}
.event-ticket-item:last-child {
        margin-right: 0
}
.event-ticket-item:nth-child(2n) {
        margin-right: 0;
        margin-left: auto
}
    }
@media (min-width: 75em) {
.event-ticket-item {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        max-width: calc(99.9% * 1/3 - (32px - 32px * 1/3));
        width: calc(99.9% * 1/3 - (32px - 32px * 1/3))
}
.event-ticket-item:nth-child(1n) {
        margin-right: 32px;
        margin-left: 0
}
.event-ticket-item:last-child {
        margin-right: 0
}
.event-ticket-item:nth-child(3n) {
        margin-right: 0;
        margin-left: auto
}
    }
.event-ticket-item__content {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
.event-ticket-item__thumbnail {
    width: 120px;
    aspect-ratio: 16/9;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: center;
       object-position: center;
    position: relative;
}
.event-ticket-item__thumbnail:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/placeholder-16-9.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
.special-access-list__search-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    gap: 12px;
}
@media (min-width: 48em) {
.special-access-list__search-container {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: start;
            -ms-flex-align: start;
                align-items: flex-start;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}

        .special-access-list__search-container > div:first-of-type {
            width: 100%;
        }

        .special-access-list__search-container button {
            padding: 4px 12px;
        }
    }
.input-date__container {
    position: relative;
}
.input-date__placeholder-container {
    position: absolute;
    border-radius: 8px;
    bottom: 0;
    left: 0;
    height: 44px;
    padding: 8px 16px;
    width: 100%;
    background-color: #efefef;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.wysiwyg-content {
    color: #78717f;
}
.wysiwyg-content p {
        color: #78717f;
    }
.wysiwyg-content h1,
    .wysiwyg-content h2,
    .wysiwyg-content h3,
    .wysiwyg-content h4,
    .wysiwyg-content h5,
    .wysiwyg-content h6 {
        color: #31263a;
    }
.wysiwyg-content a {
        color: #004378;
        font-weight: 500;
    }
.wysiwyg-content ol {
        list-style: decimal;
        list-style-position: inside;
    }
.wysiwyg-content ul {
        list-style: disc outside none;
        list-style: initial;
        list-style-position: inside;
    }
.wysiwyg-content dl {
        list-style: decimal;
    }
.wysiwyg-content ol,
    .wysiwyg-content ul,
    .wysiwyg-content dl {
        color: #78717f;
    }
/*------------------------------------*\
    # component.divider
\*------------------------------------*/
.divider__dashed {
    border-bottom: 1px dashed #aab0b7;
}
/*------------------------------------*\
    # component.ticket-holders-form
\*------------------------------------*/
@media (min-width: 48em) {
.ticker-holders-list-item__input {
        max-width: 492px
}
    }
.ticker-holders-list-item__delete__button {
    position: absolute;
    right: 20px;
    top: 20px;
    width: 32px;
    height: 32px;

    border: 1px solid #15130d;
    background-color: transparent;
    border-radius: 50%;
    color: #15130d;
    -webkit-transition: 0.1s all ease-in-out;
    transition: 0.1s all ease-in-out;
}
.ticker-holders-list-item__delete__button:hover,
    .ticker-holders-list-item__delete__button:focus {
        color: #31263a;
        border: 1px solid #31263a;
        background-color: transparent;
    }
.ticker-holders-list-item__delete__button:active {
        color: #31263a;
        border: 1px solid #31263a;
        background-color: transparent;
    }
.ticker-holders-list-item__delete__button:disabled {
        opacity: 0.5;
    }
/**
* Import: block
* Description: specific website/app blocks
*/
/*------------------------------------*\
    # block.header
\*------------------------------------*/
.header {
    background: white;
}
/*------------------------------------*\
    # block.nav
\*------------------------------------*/
.nav {
    position: relative;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
@media (max-width: 61.9375em) {
.nav {
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        position: absolute;
        z-index: 15;
        margin: 0;
        top: 0;
        right: 0;
        height: 100vh;
        width: 100%;
        -webkit-transform: translateX(100%);
                transform: translateX(100%);
        -webkit-transition: all 0.2s ease-out;
        transition: all 0.2s ease-out;
        padding-top: 16px;
        background: white
}
    }
@media (min-width: 62em) {
.nav {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
@media (max-width: 61.9375em) {
.nav.is-visible {
            -webkit-transform: translateX(0);
                    transform: translateX(0);
            -webkit-transition: all 0.25s ease-out;
            transition: all 0.25s ease-out;
            overflow-y: auto;
            padding-bottom: 100px
    }
        }
.nav__other {
    display: block;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
}
@media (max-width: 61.9375em) {
    }
@media (min-width: 62em) {
.nav__other {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center
}

        .nav__other .notification {
            margin-left: 20px;
        }
    }
@media (max-width: 61.9375em) {
.nav__links {
        padding-top: 12px;
        -webkit-box-ordinal-group: 2;
            -ms-flex-order: 1;
                order: 1
}
    }
@media (min-width: 62em) {
.nav__links {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
@media (max-width: 61.9375em) {
.nav__links__item {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: start;
            -ms-flex-pack: start;
                justify-content: flex-start;
        width: 100%
}
    }
.nav__link {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    font-weight: 700;
    font-size: 12px;
    line-height: 24px;
    color: #004378;
}
@media (max-width: 61.9375em) {
.nav__link {
        display: block;
        padding: 10px 0;
        padding-left: 20px
}
    }
@media (min-width: 62em) {
.nav__link {
        padding: 24px;
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
}
    }
.nav__link__logo {
    height: 24px;
    width: auto;
    margin-right: 8px;
}
.nav__link.active {
    color: #09a593;
}
.nav__link--home {
    padding: 24px 24px 28px;
    padding-left: 0;
    font-weight: 500;
}
@media (max-width: 61.9375em) {
.nav__link--home {
        display: none
}
    }
.nav__link--auth {
    color: #09a593;
}
.nav__link--auth:hover,
    .nav__link--auth:focus {
        color: #23d5c1;
    }
.nav__link--pipe {
    color: rgba(0, 0, 0, .2);
    font-weight: 500;
    padding: 24px 0px;
}
@media (max-width: 61.9375em) {
.nav__link--pipe {
        display: none
}
    }
@media (max-width: 61.9375em) {
.nav__mobile-title {
        font-size: 16px;
        line-height: 24px;
        padding-left: 20px;
        margin-bottom: 16px
}
    }
@media (min-width: 62em) {
.nav__mobile-title {
        display: none
}
    }
.nav__dashboard-menu {
    padding: 8px 0;
}
/*------------------------------------*\
    # block.footer
\*------------------------------------*/
.footer {
    margin-bottom: 32px;
    border-top: 1px solid rgba(0, 0, 0, .1);
}
@media (max-width: 47.9375em) {
.footer {
        display: none
}
    }
@media (max-width: 47.9375em) {
.footer__main {
        text-align: center
}
    }
@media (min-width: 48em) {
.footer__main {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
/*------------------------------------*\
    # block.modal
\*------------------------------------*/
.modal {

    position: fixed;
    top: 0;
    left: 0;
    z-index: 20;

    width: 100%;
    height: 100%;

    pointer-events: none;
    -webkit-transition: all 0.1s;
    transition: all 0.1s;
    background-color: rgba(49, 38, 58, 0.6);

    pointer-events: all;
    -webkit-animation: 0.2s fadeIn ease-in-out;
            animation: 0.2s fadeIn ease-in-out;
}
@-webkit-keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
@keyframes fadeIn {
        from {
            opacity: 0;
        }

        to {
            opacity: 1;
        }
    }
@media (min-width: 62em) {
.modal {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center;
        -webkit-box-align: center;
            -ms-flex-align: center;
                align-items: center;
}
    }
.modal__wrapper {

    background-color: white;
    -webkit-box-shadow: 1px 1px 8px -3px rgba(0, 0, 0, .4);
            box-shadow: 1px 1px 8px -3px rgba(0, 0, 0, .4);
    /* transition: all 0.2s; */
    overflow-y: auto;
    max-height: 100%;
    -webkit-animation: 0.1s slideIn ease-in-out;
            animation: 0.1s slideIn ease-in-out;
    border-radius: 10px;
}
@-webkit-keyframes slideIn {
        from {
            -webkit-transform: translateY(-10%);
                    transform: translateY(-10%);
        }

        to {
            -webkit-transform: translateY(0);
                    transform: translateY(0);
        }
    }
@keyframes slideIn {
        from {
            -webkit-transform: translateY(-10%);
                    transform: translateY(-10%);
        }

        to {
            -webkit-transform: translateY(0);
                    transform: translateY(0);
        }
    }
@media (max-width: 61.9375em) {
.modal__wrapper {
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        visibility: none
}
    }
@media (min-width: 62em) {
.modal__wrapper {
        position: relative;
        margin: 24px;
        width: 500px
}
    }
@media (max-width: 61.9375em) {
.modal.is-visible .modal__wrapper {
        -webkit-transform: translateY(0px);
                transform: translateY(0px);
        visibility: visible;
        pointer-events: all
}
    }
.modal__content {
    padding: 28px;
    padding-top: 0;
    overflow-y: scroll;
}
.modal__content > .field:first-child {
        margin-top: 0;
    }
@media (min-width: 62em) {
.modal__content {
        max-height: 450px
}
    }
.modal__close {
    padding: 8px;
    margin-right: -8px;
    margin-bottom: -4px;
}
.modal__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    padding: 16px 20px;
}
@media (min-width: 62em) {
.modal__header {
        padding: 24px 28px
}
    }
.modal__footer {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    padding: 20px 28px;
}
@media (max-width: 47.9375em) {
        .modal__footer button {
            -webkit-box-flex: 1;
                -ms-flex: 1;
                    flex: 1;
        }
    }
.modal__submit {
    width: 100%;
    color: #09a593;
    font-size: 14px;
    line-height: 16px;
    height: 44px;
    line-height: 44px;
}
@media (min-width: 62em) {
.modal__submit {
        height: 60px;
        line-height: 60px
}
    }
.modal__title {
    font-size: 16px;
    line-height: 24px;
    font-weight: 700;
}
@media (min-width: 62em) {
.modal__title {
        font-size: 20px;
        line-height: 28px
}
    }
@media (max-width: 61.9375em) {
.modal--large {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-orient: vertical;
        -webkit-box-direction: normal;
            -ms-flex-direction: column;
                flex-direction: column;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
@media (max-width: 61.9375em) {
.modal--large .modal__wrapper:first-child {
            height: 100%
    }
        }
@media (min-width: 62em) {
.modal--large .modal__wrapper:first-child {
            max-width: 586px;
            width: 100%
    }
        }
.modal--large .modal__content {
        min-height: 30vh;
    }
.modal--large .modal__footer {
        margin-top: auto;
    }
@media (max-width: 61.9375em) {
.modal--extra-large .modal__wrapper:first-child {
            height: 100%
    }
        }
@media (min-width: 62em) {
.modal--extra-large .modal__wrapper:first-child {
            max-width: 792px;
            width: 100%
    }
        }
.modal--extra-large .modal__content {
        min-height: 30vh;
    }
/**
* Import: views
* Description: specific website/app views (example: 404 view, login view)
*/
/* @import 'views.404.css'; */
/*------------------------------------*\
    # views.teams
\*------------------------------------*/
.teams {
    display: flex;
    flex-flow: row wrap;
    margin-top: 16px;
}
.event-details {
    padding-top: 32px;
    padding-bottom: 40px;
    background-color: #ffffff;
}
.event-details__header {
}
.event-details__banner-container {
    width: 100%;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.event-details__banner {
    aspect-ratio: 21/9;
    width: 100%;
    -o-object-fit: cover;
       object-fit: cover;
    position: relative;
}
.event-details__banner:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/placeholder-16-9.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
.event-details__metadata {
    margin-top: 32px;
}
@media (min-width: 48em) {
.event-details__metadata {
        margin-top: 64px
}
    }
.event-details__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
}
.event-details__content {
    margin-top: 60px;
    padding-bottom: 100px;
}
/*------------------------------------*\
    # views.auth
\*------------------------------------*/
@media (min-width: 62em) {
.auth {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex
}
    }
@media (max-width: 47.9375em) {
.auth__form-container {
        padding: 0 20px
}
    }
@media (min-width: 62em) {
.auth__form-container {
        -webkit-box-flex: 1;
            -ms-flex-positive: 1;
                flex-grow: 1
}
    }
@media (max-width: 61.9375em) {
.auth__visual {
        display: none
}
    }
@media (min-width: 62em) {
.auth__visual {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 12;
            -ms-flex-positive: 12;
                flex-grow: 12;
        min-height: 80vh;
        background-image: url('../assets/images/auth-background.jpg');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat
}
    }
@media (min-width: 90em) {
.auth__visual {
        -webkit-box-flex: 7;
            -ms-flex-positive: 7;
                flex-grow: 7
}
    }
@media (min-width: 48em) {
.auth__wrapper {
        padding-right: 96px;
        padding-left: 96px
}
    }
@media (min-width: 48em) {
.auth__wrapper--header {
        padding-top: 76px
}
    }
@media (max-width: 47.9375em) {
.auth__wrapper--form {
        margin-bottom: 40px
}
    }
@media (min-width: 48em) {
.auth__wrapper--form {
        padding-top: 32px;
        padding-bottom: 64px
}
    }
.auth__logo {
    margin-top: 10px;
}
.auth__header {
    background: white;
}
@media (max-width: 61.9375em) {
.auth__header {
        padding-top: 8px;
        margin-left: -20px;
        width: calc(100% + 40px);
        padding-left: 20px
}
    }
.auth__header__title {
    font-size: 20px;
    line-height: 32px;
}
@media (min-width: 48em) {
.auth__header__title {
        font-size: 24px;
        line-height: 40px
}
    }
.auth__header__link {
    display: block;
    padding: 12px 16px;
    color: rgba(49, 38, 58, 0.4);
    font-size: 14px;
    line-height: 20px;
}
.auth__header__link.active {
        position: relative;
        color: #09a593;
    }
.auth__header__link.active:after {
            content: '';
            position: absolute;
            bottom: 0;
            left: 50%;
            -webkit-transform: translateX(-50%);
                    transform: translateX(-50%);
            width: 0;
            height: 0;
            border-left: 7px solid transparent;
            border-right: 7px solid transparent;
            border-bottom: 7px solid #f4f5f5;
        }
@media (min-width: 48em) {
.auth__header__link.active:after {
                border-left: 10px solid transparent;
                border-right: 10px solid transparent;
                border-bottom: 10px solid #f4f5f5
        }
            }
@media (min-width: 48em) {
.auth__header__link {
        padding: 16px 24px;
        font-size: 16px;
        line-height: 24px
}
    }
@media (min-width: 62em) {
.auth__header__link {
        font-size: 20px;
        line-height: 28px
}
    }
.auth__header__link:first-child {
        margin-left: -16px;
    }
@media (min-width: 48em) {
.auth__header__link:first-child {
            margin-left: -24px
    }
        }
.auth__submit {
    width: 100%;
}
.auth__forgot {
    font-size: 12px;
    line-height: 16px;
    color: rgba(49, 38, 58, 0.4);
}
.auth__footer {
    margin-top: 20px;
}
.auth__footer .field {
    margin-top: 0;
}
/*------------------------------------*\
    # views.events
\*------------------------------------*/
.events__badges {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
@media (min-width: 62em) {
.events .page-header .page-header__container {
        display: -webkit-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-pack: justify;
            -ms-flex-pack: justify;
                justify-content: space-between
}
    }
/*------------------------------------*\
    # views.shared
\*------------------------------------*/
.shared {
    display: block;
}
@media (min-width: 62em) {
.shared {
        max-width: calc(99.9% * 10/12);
        margin-left: auto;
        margin-right: auto
}
.shared:before {
        content: '';
        display: table
}
.shared:after {
        content: '';
        display: table;
        clear: both
}
    }
/*------------------------------------*\
    # views.forgot-password
\*------------------------------------*/
.forgot-password__content {
    width: 100%;
}
@media (min-width: 48em) {
.forgot-password__content {
        max-width: calc(99.9% * 8/12);
        margin-left: auto;
        margin-right: auto
}
.forgot-password__content:before {
        content: '';
        display: table
}
.forgot-password__content:after {
        content: '';
        display: table;
        clear: both
}
    }
@media (min-width: 62em) {
.forgot-password__content {
        max-width: calc(99.9% * 6/12);
        margin-left: auto;
        margin-right: auto
}
.forgot-password__content:before {
        content: '';
        display: table
}
.forgot-password__content:after {
        content: '';
        display: table;
        clear: both
}
    }
@media (max-width: 47.9375em) {
.forgot-password__button {
        width: 100%
}
    }
.general-error {
    position: absolute;
    z-index: 1;
    /*Header height*/
    top: 76px;
    height: calc(100% - 76px);
    width: 100%;
    background-color: #f4f5f5;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.general-error__content {
    margin-top: -150px;
}
.audio-details {
    padding-top: 32px;
    padding-bottom: 40px;
    background-color: #ffffff;
}
.audio-details__header {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-column-gap: 7vw;
       -moz-column-gap: 7vw;
            column-gap: 7vw;
    row-gap: 32px;
    width: 100%;
}
@media (min-width: 48em) {
.audio-details__header {
        -webkit-box-orient: horizontal;
        -webkit-box-direction: normal;
            -ms-flex-direction: row;
                flex-direction: row
}
    }
@media (min-width: 48em) {
.audio-details__metadata {
        /* max-width: 385px; */
}
    }
.audio-details__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    margin-top: 24px;
}
@media (min-width: 48em) {
.audio-details__controls {
        -webkit-box-pack: unset;
            -ms-flex-pack: unset;
                justify-content: unset;
        gap: 20px
}
    }
.audio-details__thumbnail {
    width: 100%;
    aspect-ratio: 1/1;
    max-width: 400px;
    -o-object-fit: cover;
       object-fit: cover;
    -o-object-position: contain;
       object-position: contain;
    border-radius: 4px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    margin: 0 auto;
    position: relative;
}
.audio-details__thumbnail:after {
        content: ' ';
        display: block;
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        background-image: url('../assets/images/placeholder-16-9.png');
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
    }
@media (min-width: 450px) {
    }
@media (min-width: 48em) {
.audio-details__thumbnail {
        height: 280px;
        width: 280px;
        margin: unset
}
    }
.audio-details__play {
    height: 44px;
    width: 44px;
    -ms-flex-negative: 0;
        flex-shrink: 0;
    border-radius: 44px;
    padding: 0;
}
.audio-details__play:hover img, .audio-details__play:focus img {
            -webkit-transform: scale(1.2);
                    transform: scale(1.2);
        }
.audio-details__play img {
        height: 16px;
        width: 16px;
        -ms-flex-negative: 0;
            flex-shrink: 0;
        -o-object-fit: cover;
           object-fit: cover;
        -webkit-transition: 0.2s -webkit-transform ease-in-out;
        transition: 0.2s -webkit-transform ease-in-out;
        transition: 0.2s transform ease-in-out;
        transition: 0.2s transform ease-in-out, 0.2s -webkit-transform ease-in-out;
    }
.audio-details__content {
    margin-top: 60px;
    padding-bottom: 100px;
}
.video-details {
    padding-top: 32px;
    padding-bottom: 40px;
    background-color: #ffffff;
}
.video-details__metadata {
    margin-top: 32px;
}
@media (min-width: 48em) {
.video-details__metadata {
        margin-top: 64px
}
    }
.video-details__controls {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    gap: 20px;
    margin-left: -16px;
}
.video-details__video-container {
    background-color: black;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.video-details__video {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
}
.video-details__video iframe {
        width: 100%;
        height: 100%;
        max-width: 800px;
        aspect-ratio: 16/9;
    }
.video-details__content {
    margin-top: 60px;
    padding-bottom: 100px;
}
.content-page {
    background-color: #ffffff;
    padding-top: 60px;
    padding-bottom: 40px;
}
@media (min-width: 48em) {
.content-page {
        padding-top: 40px
}
    }
.content-page__heading-container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
    margin-bottom: 32px;
}
@media (min-width: 48em) {
.content-page__heading-options {
        display: none
}
    }
@media (max-width: 47.9375em) {
.content-page__controls-options {
        display: none
}
    }
.content-page__title {
    font-family: 'Muli', Helvetica, Arial, sans-serif;
    text-transform: capitalize;
}
@media (max-width: 47.9375em) {
.content-page__title {
        font-size: 20px;
        line-height: 24px
}
    }
@media (min-width: 48em) {
.content-page__title {
        font-size: 32px;
        line-height: 40px
}
    }
.content-page__controls {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
    grid-column-gap: 20px;
    grid-row-gap: 12px;
}
.content-page__controls-actions {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
}
@media (min-width: 48em) {
.content-page__controls-actions {
        -webkit-box-pack: end;
            -ms-flex-pack: end;
                justify-content: flex-end
}
    }
.content-page__body {
    background: #f4f5f5;
    padding-top: 40px;
    padding-bottom: 40px;
}
/*------------------------------------*\
    # views.buy-tickets
\*------------------------------------*/
.buy-tickets {
    padding-top: 16px;
    padding-bottom: 40px;
    background-color: #ffffff;
}
.buy-tickets__container {
    margin-top: 32px;
}
@media (min-width: 48em) {
.buy-tickets__container {
        margin-top: 64px;
        margin-bottom: 80px
}
    }
.buy-tickets__title {
    margin: 32px 0 24px 0;
    font-family: 'Muli', Helvetica, Arial, sans-serif;
}
@media (min-width: 48em) {
.buy-tickets__title {
        margin: 32px 0
}
    }
.buy-tickets__details__container {
    gap: 4px;
    margin-bottom: 40px;
}
@media (min-width: 48em) {
.buy-tickets__details__container {
        gap: 8px
}
    }
.buy-tickets__input {
    /* For Firefox */
    -moz-appearance: textfield;
    width: 64px;
}
/* For Chrome, Safari, Edge, Opera */
.buy-tickets__input::-webkit-outer-spin-button,
    .buy-tickets__input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
.buy-tickets__button {
    width: 32px;
    border-radius: 50%;
    margin-top: 6px;
}
.buy-tickets__terms__container {
    background-color: #f8f9f9;
    padding: 12px;
    margin: 40px 0;
}
@media (min-width: 48em) {
.buy-tickets__terms__container {
        padding: 12px 76px 12px 12px
}
    }
@media (min-width: 48em) {
.buy-tickets__terms__content {
        max-width: 404px
}
    }
.buy-tickets__subtotal__container {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    gap: 32px;
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.buy-tickets__buttons {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 47.9375em) {
.buy-tickets__buttons {
        gap: 16px
}
    }
/*------------------------------------*\
    # views.payment-status
\*------------------------------------*/
.payment-status {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    height: 100%;
}
@media (min-width: 48em) {
.payment-status {
        min-height: 70vh
}
    }
.payment-status__container {
    padding: 20px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    margin-top: 68px;
}
@media (min-width: 48em) {
.payment-status__container {
        background-color: #ffffff;
        min-width: 800px;
        padding: 40px;
        border-radius: 16px;
        margin-top: 0
}
    }
.payment-status__icon {
    margin-bottom: 32px;
}
.payment-status__title {
    margin-bottom: 12px;
}
.payment-status__description {
    margin-bottom: 48px;
}
/**
* Import: utils
* Description: reusable utilities such as floats, spacers etc.
*/
/*------------------------------------*\
    # utils.width
\*------------------------------------*/
.u-width--full {
    width: 100%;
}
.u-width--content {
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.u-width--150 {
    width: 150px;
}
.u-maxwidth--464 {
    max-width: 464px;
}
.u-maxwidth--464__responsive {
    width: 100%;
}
@media (min-width: 48em) {
.u-maxwidth--464__responsive {
        max-width: 464px
}
    }
.u-maxwidth--592 {
    max-width: 592px;
}
/*------------------------------------*\
    # utils.spacers
\*------------------------------------*/
.s- {
}
/* Top */
.s-top {
}
.s-top--tny {
    margin-top: 4px;
}
.s-top--sml {
    margin-top: 8px;
}
.s-top--med {
    margin-top: 16px;
}
.s-top--lrg {
    margin-top: 32px;
}
.s-top--negative {
    margin-top: -4px;
}
/* Right */
.s-right {
}
.s-right--tny {
    margin-right: 4px;
}
.s-right--sml {
    margin-right: 8px;
}
.s-right--med {
    margin-right: 16px;
}
.s-right--lrg {
    margin-right: 32px;
}
/* Bottom */
.s-bottom {
}
.s-bottom--tny {
    margin-bottom: 4px;
}
.s-bottom--sml {
    margin-bottom: 8px;
}
.s-bottom--med {
    margin-bottom: 16px;
}
.s-bottom--lrg {
    margin-bottom: 32px;
}
/* Left */
.s-left {
}
.s-left--tny {
    margin-left: 4px;
}
.s-left--sml {
    margin-left: 8px;
}
.s-left--med {
    margin-left: 16px;
}
.s-left--lrg {
    margin-left: 32px;
}
.s-left--auto {
    margin-left: auto;
}
/*------------------------------------*\
    # utils.type
\*------------------------------------*/
/* Alignment */
.t-left {
    text-align: left;
}
.t-center {
    text-align: center;
}
.t-right {
    text-align: right;
}
.t-regular {
    font-weight: 500;
}
.t-bold {
    font-weight: 700;
}
.t-semibold {
    font-weight: 600;
}
.t-extrabold {
    font-weight: 800;
}
/* Case */
.t-upper {
    text-transform: uppercase;
}
/* Sizes */
.t-alpha {
    font-size: 40px;
    line-height: 56px;
}
.t-beta {
    font-size: 32px;
    line-height: 48px;
}
.t-gamma {
    font-size: 16px;
    line-height: 24px;
}
@media (min-width: 48em) {
.t-gamma {
        font-size: 20px;
        line-height: 28px
}
    }
@media (min-width: 62em) {
.t-gamma {
        font-size: 24px;
        line-height: 32px
}
    }
.t-delta {
    font-size: 20px;
    line-height: 32px;
}
.t-epsilon {
    font-size: 16px;
    line-height: 24px;
}
.t-zeta {
    font-size: 12px;
    line-height: 20px;
}
@media (min-width: 62em) {
.t-zeta {
        font-size: 14px;
        line-height: 24px
}
    }
.t-eta {
    font-size: 12px;
    line-height: 20px;
}
.t-theta {
    font-size: 11px;
    line-height: 16px;
}
@media (min-width: 48em) {
.t-theta {
        font-size: 12px;
        line-height: 16px
}
    }
.t-iota {
    font-size: 11px;
    line-height: 16px;
}
.t-label {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 400;
    text-transform: uppercase;
    margin-bottom: 4px;
}
.t-label--sml {
    font-size: 11px;
    line-height: 16px;
    letter-spacing: 2px;
    margin-bottom: 0;
}
/* Colors */
.t-primary {
    color: #004378;
}
.t-secondary {
    color: #09a593;
}
.t-negative {
    color: white;
}
.t-text {
    color: #31263a;
}
.t-faded {
    color: #78717f;
}
.t-warning {
    color: #f44336;
}
.t-info {
    color: #2f8bff;
}
/* Shorten long links */
.t-shorten {
    /* These are technically the same, but use both */
    word-wrap: break-word;
    word-wrap: break-word;

    -ms-word-break: break-all;
    /* This is the dangerous one in WebKit, as it breaks things wherever */
    word-break: break-all;
    /* Instead use this non-standard one: */
    word-break: break-word;

    /* Adds a hyphen where the word breaks, if supported (No Blink) */
    -webkit-hyphens: auto;
        -ms-hyphens: auto;
            hyphens: auto;
}
.t-wrap {
    word-wrap: break-word;
    word-wrap: break-word;
}
/** New styleguide sizes */
.t-micro {
    font-size: 11px;
    line-height: 16px;
}
.t-tiny {
    font-size: 12px;
    line-height: 16px;
}
.t-small {
    font-size: 14px;
    line-height: 20px;
}
@media (max-width: 47.9375em) {
.t-base {
        font-size: 14px;
        line-height: 20px
}
    }
@media (min-width: 48em) {
.t-base {
        font-size: 16px;
        line-height: 20px
}
    }
@media (max-width: 47.9375em) {
.t-medium {
        font-size: 16px;
        line-height: 20px
}
    }
@media (min-width: 48em) {
.t-medium {
        font-size: 20px;
        line-height: 24px
}
    }
@media (max-width: 47.9375em) {
.t-large {
        font-size: 20px;
        line-height: 24px
}
    }
@media (min-width: 48em) {
.t-large {
        font-size: 24px;
        line-height: 28px
}
    }
@media (max-width: 47.9375em) {
.t-xlarge {
        font-size: 24px;
        line-height: 28px
}
    }
@media (min-width: 48em) {
.t-xlarge {
        font-size: 32px;
        line-height: 40px
}
    }
@media (max-width: 47.9375em) {
.t-xxlarge {
        font-size: 24px;
        line-height: 40px
}
    }
@media (min-width: 48em) {
.t-xxlarge {
        font-size: 40px;
        line-height: 48px
}
    }
@media (max-width: 47.9375em) {
.t-xxxlarge {
        font-size: 40px;
        line-height: 48px
}
    }
@media (min-width: 48em) {
.t-xxxlarge {
        font-size: 48px;
        line-height: 56px
}
    }
.t-xxxxlarge {
    font-size: 60px;
    line-height: 68px;
}
.t-sans {
    font-family: 'Muli', Helvetica, Arial, sans-serif;
}
.t-sans--alt {
    font-family: 'Lato', Helvetica, Arial, sans-serif;
}
/**New styleguide colors **/
.t-text-1 {
    color: #15130d;
}
.t-text-2 {
    color: #31263a;
}
.t-text-3 {
    color: #78717f;
}
.t-text-4 {
    color: #aab0b7;
}
/*------------------------------------*\
    # utils.position
\*------------------------------------*/
.u-pos {
}
.u-pos--rel {
    position: relative;
}
.u-pos--abs {
    position: absolute;
}
/*------------------------------------*\
    # utils.color
\*------------------------------------*/
.u-color--success {
    color: #4caf50;
}
/*------------------------------------*\
    # utils.opacity
\*------------------------------------*/
.u-opacity {
}
.u-opacity--100 {
    opacity: 1;
}
.u-opacity--80 {
    opacity: 0.8;
}
.u-opacity--60 {
    opacity: 0.6;
}
.u-opacity--40 {
    opacity: 0.4;
}
.u-opacity--20 {
    opacity: 0.2;
}
/*------------------------------------*\
    # utils.display
\*------------------------------------*/
.u-display--none {
    display: none;
}
@media (max-width: 47.9375em) {
.u--desktop--only {
        display: none
}
    }
@media (min-width: 48em) {
.u--mobile--only {
        display: none
}
    }
/*------------------------------------*\
    # utils.flex
\*------------------------------------*/
.f {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
}
.f--inline {
    display: -webkit-inline-box;
    display: -ms-inline-flexbox;
    display: inline-flex;
}
.f--one {
    -webkit-box-flex: 1;
        -ms-flex: 1;
            flex: 1;
}
.f--two {
    -webkit-box-flex: 2;
        -ms-flex: 2;
            flex: 2;
}
.f--three {
    -webkit-box-flex: 3;
        -ms-flex: 3;
            flex: 3;
}
.f--column {
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
        -ms-flex-direction: column;
            flex-direction: column;
}
.f--top {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.f--start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.f--end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.f--center {
    -webkit-box-pack: justify;
        -ms-flex-pack: justify;
            justify-content: space-between;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.f--align-center {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
.f--center-alt {
    -webkit-box-pack: center;
        -ms-flex-pack: center;
            justify-content: center;
    -webkit-box-align: space-between;
        -ms-flex-align: space-between;
            align-items: space-between;
}
.f--center--self {
    justify-self: center;
    -ms-flex-item-align: center;
        align-self: center;
}
.f--end-self {
    justify-self: flex-end;
}
.f--end-self--alt {
    -ms-flex-item-align: end;
        align-self: flex-end;
}
.f--align-start {
    -webkit-box-align: start;
        -ms-flex-align: start;
            align-items: flex-start;
}
.f--align-end {
    -webkit-box-align: end;
        -ms-flex-align: end;
            align-items: flex-end;
}
.f--justify-end {
    -webkit-box-pack: end;
        -ms-flex-pack: end;
            justify-content: flex-end;
}
.f--justify-start {
    -webkit-box-pack: start;
        -ms-flex-pack: start;
            justify-content: flex-start;
}
.f--wrap {
    -ms-flex-wrap: wrap;
        flex-wrap: wrap;
}
.f--gap {
    -webkit-column-gap: 20px;
       -moz-column-gap: 20px;
            column-gap: 20px;
    row-gap: 12px;
}
.f--gap-small {
    -webkit-column-gap: 8px;
       -moz-column-gap: 8px;
            column-gap: 8px;
    row-gap: 4px;
}
.f--center--mobile {
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
}
@media (max-width: 47.9375em) {
.f--center--mobile {
        -webkit-box-pack: center;
            -ms-flex-pack: center;
                justify-content: center
}
    }
.f--shrink-zero {
    -ms-flex-negative: 0;
        flex-shrink: 0;
}
.lost-flex-container {
    display: flex;
    flex-flow: row wrap;
}
.g-4 {
    gap: 4px;
}
.g-8 {
    gap: 8px;
}
.g-16 {
    gap: 16px;
}
.g-20 {
    gap: 20px;
}
.g-32 {
    gap: 32px;
}
.pointer {
    cursor: pointer;
}
.pointer--no-select {
        cursor: pointer;
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }
/*------------------------------------*\
    # utils.bg
\*------------------------------------*/
.u-bg--white {
    background-color: #fff;
}
/**
* Import: plugins
* Description: 3rd party code, external plugin CSS etc
* Note: when importing code from a 3rd party it should be stripped off
* any vendor prefixes since autoprefixer will use project specific vendor prefixes
*/
/*------------------------------------*\
    # plugins.fontface
\*------------------------------------*/
/*------------------------------------*\
    # plugins.datepicker
\*------------------------------------*/
.SingleDatePicker {
    width: 100%;
}
.SingleDatePickerInput {
    border: 0;
    border-bottom: 1px solid rgba(0, 0, 0, .1);
    background: transparent;
    padding-left: 0;
    width: 100%;
}
.DateInput {
    background: none;
    width: 100%;
}
.DateInput_input {
    line-height: 44px;
    font-size: 16px;
    line-height: 16px;
    height: 43px;
    color: #31263a;
    background: transparent;
}
.SingleDatePickerInput__withBorder {
    border-radius: 0;
}
.DateInput_input__focused {
    border-bottom: 1px solid #004378;
    color: #004378;
}
.DateInput_input__focused::-webkit-input-placeholder {
        color: #004378;
    }
.DateInput_input__focused::-moz-placeholder {
        color: #004378;
    }
.DateInput_input__focused::-ms-input-placeholder {
        color: #004378;
    }
.DateInput_input__focused::placeholder {
        color: #004378;
    }
/*------------------------------------*\
    # plugins.dropzone
\*------------------------------------*/
.dropzone__container {
    margin-top: 4px;
    position: relative;
}
.dropzone__area {
    border: 1px dashed #78717f;
    background-color: #f4f5f5;
    height: 80px;
    border-radius: 8px;
    cursor: pointer;
    z-index: 1;
}
.dropzone__area-image {
    width: 100%;
    height: 180px;
    -o-object-fit: contain;
       object-fit: contain;
    -o-object-position: center;
       object-position: center;
}
.dropzone__area-audio {
    width: 100%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
        -ms-flex-align: center;
            align-items: center;
    border: 1px dashed transparent;
    -webkit-column-gap: 12px;
       -moz-column-gap: 12px;
            column-gap: 12px;
    padding: 0 16px;
    cursor: default;
}
.dropzone__remove-preview {
    position: absolute;
    top: 4px;
    right: 4px;
    background-color: white;
    padding: 0;
    border-radius: 50px;
    height: 32px;
    width: 32px;
    -webkit-box-shadow: 1px 1px 8px -3px rgba(0, 0, 0, .4);
            box-shadow: 1px 1px 8px -3px rgba(0, 0, 0, .4);
    border: 1px solid #efefef;
    -webkit-transition: 0.1s -webkit-box-shadow ease-in-out;
    transition: 0.1s -webkit-box-shadow ease-in-out;
    transition: 0.1s box-shadow ease-in-out;
    transition: 0.1s box-shadow ease-in-out, 0.1s -webkit-box-shadow ease-in-out;
}
.dropzone__remove-preview:hover {
        border: 1px solid rgba(49, 38, 58, 0.2);
        -webkit-box-shadow: 1px 1px 8px -3px #09a593;
                box-shadow: 1px 1px 8px -3px #09a593;
    }
.dropzone__label {
    position: absolute;
    text-align: center;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
    pointer-events: none;
}
.dropzone__label--actionable {
    pointer-events: unset;
}
.dropzone__audio {
}
.dropzone__audio--tooltip:after {
        font-size: 12px;
        line-height: 20px;
        position: absolute;
        top: 50%;
        left: 0;
        width: 100%;
        -webkit-transform: translateY(-50%);
                transform: translateY(-50%);
        text-align: center;
        content: 'Select an audio file';
        color: #78717f;
    }
.dropzone {
    margin-bottom: 28px;
    width: 100%;
    height: 28px;
    /* border: 1px dashed rgba(0, 0, 0, 0.2); */
    -webkit-transition: all 0.15s ease-in-out;
    transition: all 0.15s ease-in-out;
    position: relative;
}
.dropzone:after {
        position: absolute;
        content: 'Change profile photo';
        top: 0;
        left: 0;
        width: 100%;
        padding-top: 8px;
        text-align: center;
        font-family: 'Lato', Helvetica, Arial, sans-serif;
        font-size: 13px;
        line-height: 20px;
        color: #09a593;
        -webkit-transition: all 0.2s ease-in-out;
        transition: all 0.2s ease-in-out;
    }
.dropzone:hover,
    .dropzone:focus {
        border-color: rgba(0, 0, 0, .4);
        cursor: pointer;
    }
.dropzone:hover:after, .dropzone:focus:after {
            color: rgb(10, 115, 103);
        }
.dropzone__images--overlay {
            padding: 4px 12px;
            text-align: center;
            background: white;
            height: 150px;
            z-index: 2;
            position: relative;
        }
.dropzone__images--overlay:empty {
                display: none;
            }
.dropzone__image {
        display: inline-block;
        position: relative;
        margin-top: 12px;
        margin-right: 12px;
    }
.dropzone__image img {
            max-height: 40px;
        }
/* Slider */
.slick-slider {
    position: relative;

    display: block;
    -webkit-box-sizing: border-box;
            box-sizing: border-box;

    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;

    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    position: relative;

    display: block;
    overflow: hidden;

    margin: 0;
    padding: 0;
}
.slick-list:focus {
    outline: none;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-track,
.slick-slider .slick-list {
    -webkit-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    position: relative;
    top: 0;
    left: 0;

    display: block;
    margin-left: auto;
    margin-right: auto;
}
.slick-track:before,
.slick-track:after {
    display: table;

    content: '';
}
.slick-track:after {
    clear: both;
}
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slide {
    display: none;
    float: left;

    height: 100%;
    min-height: 1px;
}
[dir='rtl'] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: block;
}
.slick-loading .slick-slide {
    visibility: hidden;
}
.slick-vertical .slick-slide {
    display: block;

    height: auto;

    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}
.react-select__control {
    border-width: 0 !important;
    cursor: pointer;
    white-space: nowrap;
    border-radius: 8px !important;
    border-color: transparent !important;
    background-color: transparent !important;
    outline-color: transparent !important;
    min-height: 44px;

    text-decoration: none;
    font-family: 'Lato', Helvetica, Arial, sans-serif;
    font-weight: 500;

    /* Default input sizes. Add t-small for small override, etc.  */
    font-size: 15px;
    line-height: 20px;
}
.react-select__control:focus,
    .react-select__control:hover,
    .react-select__control:active {
        border-color: transparent !important;
        background-color: transparent !important;
        outline-color: transparent !important;
    }
.react-select__control:focus::-webkit-input-placeholder, .react-select__control:hover::-webkit-input-placeholder, .react-select__control:active::-webkit-input-placeholder {
            color: #004378;
        }
.react-select__control:focus::-moz-placeholder, .react-select__control:hover::-moz-placeholder, .react-select__control:active::-moz-placeholder {
            color: #004378;
        }
.react-select__control:focus::-ms-input-placeholder, .react-select__control:hover::-ms-input-placeholder, .react-select__control:active::-ms-input-placeholder {
            color: #004378;
        }
.react-select__control:focus::placeholder, .react-select__control:hover::placeholder, .react-select__control:active::placeholder {
            color: #004378;
        }
.react-select__menu {
    background-color: #f4f5f5 !important;
}
.react-select__menu > * {
        /* Default input sizes. Add t-small for small override, etc.  */
        font-size: 15px;
        line-height: 20px;
    }
.react-select__control--menu-is-open,
.react-select__control--is-focused {
    -webkit-box-shadow: unset !important;
            box-shadow: unset !important;
}
.react-select__value-container {
    background-color: #efefef !important;
    min-height: 44px;
    padding: 0 16px;
    border-top-left-radius: 8px;
    border-bottom-left-radius: 8px;
}
.react-select__multi-value {
    border: 1px solid #78717f;
    border-radius: 100px !important;
    background-color: transparent !important;
    overflow: hidden;
    padding: 0 8px;
}
.react-select__multi-value__remove {
    margin-left: 4px;
}
.react-select__multi-value__remove:hover {
        background-color: transparent !important;
    }
.react-select__indicators {
    border: 0 !important;
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    overflow: hidden;
    background-color: #efefef !important;
}
.react-select__indicators svg {
        fill: #78717f;
    }
.react-select__indicator {
    border-width: 0 !important;
}
.react-select__indicator-separator {
    display: none;
}
.quill {
    background-color: #efefef;
    border-radius: 8px;
    border-width: 0;
    outline: 0;
}
.ql-toolbar {
    border: unset !important;
    border-bottom: 1px solid #c4c5ca !important;
}
.ql-container {
    border: unset !important;
    min-height: 200px;
}
@media (max-width: 47.9375em) {
.ql-editor > * {
        font-size: 14px;
        line-height: 20px
}
    }
@media (min-width: 48em) {
.ql-editor > * {
        font-size: 16px;
        line-height: 20px
}
    }
.ql-blank {
    position: absolute;
    width: 100%;
    height: 100%;
}
/**
* Import: platform
* Description: CSS platform specific files
*/
/*------------------------------------*\
    # platform.ios
\*------------------------------------*/
.ios .page-header {
    padding-top: 48px;
}
.ios .dashboard-header {
    padding-top: 48px;
}
.ios .nav {
    padding-top: 48px;
}
.ios .hamburger {
    top: 46px;
}
.ios .page-header__back {
    top: 46px;
}
@media (max-width: 47.9375em) {
.ios .auth__wrapper--header {
        padding-top: 36px
}
    }
/**
* Import: shame
* Description: CSS shame file
* Note: to be avoided, exists only if REALLY necessary or legacy code
*/
/* @import 'shame.css'; */
